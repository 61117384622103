import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import moment from 'moment';
import { forkJoin } from 'rxjs'
import Swal from 'sweetalert2';
declare let numeral: any;

@Component({
  selector: "app-edit-deposit",
  templateUrl: "./edit-deposit.component.html",
})
export class EditDepositComponent implements OnInit, AfterViewInit {
  @ViewChild("searchValue") searchValue;
  @ViewChild("searchValueDetails") searchValueDetails;
  filteredItemsSize = -1;
  filteredItemsDetailsSize = -1;
  searchTextStr: any = "";
  searchTextDetailsStr: any = "";
  IsDateTypeReadOnly: boolean = true;
  DateRange: any;
  DateFrom: any = new Date('1901/01/01');
  DateTo: any = new Date('2099/12/30');
  companyID: any;
  public clonedDepositData: any = [];
  public dateRangeList: SelectItem[];
  public reassignRecordList: SelectItem[];
  public depositDatasource: any = [];
  public filteredDepositDatasource: any = [];
  public detailsDatasource: any = [];
  public bankList: SelectItem[];
  public filteredBankList: SelectItem[];
  public selectedBank: any;
  public isShowDetailsDialog: boolean = false;
  public isShowReassignDialog
  public selectedDeposit: any;
  public selectedRadBtn: any;
  public selectedRecords: any = '20'; //default
  public selectedPaymentHeader: any;
  public totalDepositAmount: any = 0;
  public onPreviousDetails: any = [];
  companySetting: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils,
    private translate: TranslateService
  ) {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
  }

  ngOnInit(): void {
    this.companyID = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking) || '';
    this.getAllDepositsByCompanyID(this.companyID, false);
  }

  getAllDepositsByCompanyID(companyID, filtered: boolean) {
    //use addOffset on DepositDate once retrieved from API
    this.mangoAPISrvc.showLoader(true)
    const getObj = {
      CompanyID: companyID,
      DateFrom: moment(this.DateFrom).format('YYYY-MM-DD'),
      DateTo: moment(this.DateTo).format('YYYY-MM-DD'),
      BankID: this.selectedBank || null
    }
    this.mangoAPISrvc.getAllDepositsByCompanyID(getObj).subscribe((result: any) => {
      this.totalDepositAmount = 0;

      (
            this.depositDatasource
        =   (
              result.map(
                (
                  ( item ) => {
                    (
                          this.totalDepositAmount
                      +=  (
                            numeral( item.DepositAmount ).value( )
                          )
                    );

                    return  (
                              {
                                ...item,

                                DepositDate: (
                                  new Date(
                                        (
                                          moment( item.DepositDate )
                                          .format( "YYYY-MM-DDT00:00:00" )
                                          .toString( )
                                        )
                                      )
                                )
                              }
                            );
                  }
                )
              )
            )
      );

      if (!filtered) {
        this.bankList = result.map((item) => {
          return { label: item.BankName, value: item.BankID }
        })

        this.bankList = this.mangoUtils.removeDuplicatesByProperty(this.bankList, 'value')
        this.filteredBankList = [...this.bankList]
        this.bankList.unshift({ label: "All", value: null })
      }
      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false)
    })
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('accounting') },
      { label: this.translate.instant('accounts.edit-deposit'), icon: 'ic-red' }
    ]);

    this.dateRangeList = [
      { label: this.translate.instant("All_Dates"), value: null },
      { label: this.translate.instant("reports.Todays_Date"), value: "Today" },
      { label: this.translate.instant("This-Week"), value: "Week" },
      { label: this.translate.instant("reports.This_Month"), value: "Month" },
      { label: this.translate.instant("rpt.This_Quarter"), value: "Quarter" },
      { label: this.translate.instant("rpt.This_Year"), value: "Year" },
      { label: this.translate.instant("rpt.Last_Week"), value: "lastweek" },
      { label: this.translate.instant("rpt.Last_Month"), value: "lastmonth" },
      { label: this.translate.instant("rpt.Last_Quarter"), value: "lastquarter" },
      { label: this.translate.instant("rpt.Last_Year"), value: "lastyear" },
      { label: this.translate.instant("Custom"), value: "custom" },
    ];

    this.reassignRecordList = [
      { label: this.translate.instant("all"), value: 'all' },
      { label: this.translate.instant("accounts.last_20_records"), value: '20' },
    ]
  }

  onFilter(obj, type) {
    if (type == 'header') {
      this.filteredItemsSize = obj.filteredValue.length;
      this.encrDecSrvc.addObject(
        "editDepositHeader" + AppConstants.SearchString,
        obj?.filters?.global?.value
      );
    } else {
      this.filteredItemsDetailsSize = obj.filteredValue.length;
      this.encrDecSrvc.addObject(
        "editDepositDetails" + AppConstants.SearchString,
        obj?.filters?.global?.value
      );
    }
  }

  clearSearchFilter(type) {
    if (type == 'header') {
      this.searchValue.nativeElement.value = this.searchTextStr = "";
      this.filteredItemsSize = -1;
      this.encrDecSrvc.addObject(
        "editDepositHeader" + AppConstants.SearchString,
        ""
      );
    } else {
      this.searchValueDetails.nativeElement.value = this.searchTextDetailsStr = "";
      this.filteredItemsDetailsSize = -1;
      this.encrDecSrvc.addObject(
        "editDepositDetails" + AppConstants.SearchString,
        ""
      );
    }
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  changeDateTypes() {
    this.IsDateTypeReadOnly = true;
    const obj = this.DateRange;
    if (obj == 'Today') {
      this.DateFrom = new Date();
      this.DateTo = new Date();
    } else if (obj == 'Week') {
      this.DateFrom = new Date(moment().startOf('isoWeek').format());
      this.DateTo = new Date(moment().endOf('isoWeek').format());
    } else if (obj == 'lastweek') {
      this.DateFrom = new Date(moment().startOf('isoWeek').subtract(1, 'week').format());
      this.DateTo = new Date(moment().endOf('isoWeek').subtract(1, 'week').format());
    } else if (obj == 'Month') {
      this.DateFrom = new Date(moment().startOf('month').format());
      this.DateTo = new Date(moment().endOf('month').format());
    } else if (obj == 'lastmonth') {
      this.DateFrom = new Date(moment().subtract(1, 'months').startOf('month').format());
      this.DateTo = new Date(moment().subtract(1, 'months').endOf('month').format());
    } else if (obj == 'Quarter') {
      this.DateFrom = new Date(moment().startOf('quarter').format());
      this.DateTo = new Date(moment().endOf('quarter').format());
    } else if (obj == 'lastquarter') {
      this.DateFrom = new Date(moment().subtract(1, 'quarters').startOf('quarter').format());
      this.DateTo = new Date(moment().subtract(1, 'quarters').endOf('quarter').format());
    } else if (obj == 'Year') {
      this.DateFrom = new Date(moment().startOf('year').format());
      this.DateTo = new Date(moment().endOf('year').format());
    } else if (obj == 'lastyear') {
      this.DateFrom = new Date(moment().subtract(1, 'years').startOf('year').format());
      this.DateTo = new Date(moment().subtract(1, 'years').endOf('year').format());
    } else if (obj == 'custom') {
      this.IsDateTypeReadOnly = false;
      this.DateFrom = new Date();
      this.DateTo = new Date();
    } else {
      this.DateFrom = new Date('1901-01-01');
      this.DateTo = new Date('2099-12-30');
    }

    this.getAllDepositsByCompanyID(this.companyID, true)
  }

  onChangeFilters(event, type) {
    this.getAllDepositsByCompanyID(this.companyID, true)
  }

  removeItemInClonedArray(id) {
    this.clonedDepositData = this.clonedDepositData.filter((item) => item.PaymentToBankID !== id);
  }

  removePrevDetails(id) {
    this.onPreviousDetails = this.onPreviousDetails.filter((item) => item.PaymentHeader !== id);
  }

  onRowEditInit(data: any) {
    data['IsRowEditing'] = true;
    const filtered = this.clonedDepositData.filter((item) => item.PaymentToBankID == data.PaymentToBankID);
    if (filtered.length == 0) {
      this.clonedDepositData.push({ ...data });
    }
  }

  onDetailEditInit(data: any) {
    data['IsRowEditing'] = true;
    const filtered = this.onPreviousDetails.filter((item) => item.PaymentHeaderID == data.PaymentHeaderID);
    if (filtered.length == 0) {
      this.onPreviousDetails.push({ ...data });
    }
  }

  onRowEditSave( event: any, rowData: any, type: string ){
    rowData[ 'IsRowEditing' ] = false;

    this.removeItemInClonedArray( rowData.PaymentToBankID );

    const depositObj = (
      {
        DepositDate: (
          moment( new Date( rowData.DepositDate ) )
          .format( "YYYY-MM-DD" )
        ),

        DepositMemo: rowData.DepositMemo,
        BankID: rowData.BankID
      }
    )

    this.mangoAPISrvc.showLoader( true );

    this.mangoAPISrvc.updateDepositedById(
      rowData.PaymentToBankID,
      depositObj
    )
    .subscribe(
      (
        ( result: any ) => {
          this.mangoAPISrvc.notify(
            'success',
            'Success!',
            AppConstants.updateMsg
          );

          this.mangoAPISrvc.showLoader( false );
        }
      ),

      (
        ( error ) => {
          this.mangoAPISrvc.notify(
            'error',
            'Error!',
            error
          );

          this.mangoAPISrvc.showLoader( false );
        }
      )
    );
  }

  onRowEditCancel(data: any, type) {
    if (type == 'deposit') {
      const cloned = this.clonedDepositData.filter(item => item.PaymentToBankID === data.PaymentToBankID)[0]
      data['IsRowEditing'] = false;
      data.BankName = cloned.BankName
      data.BankID = cloned.BankID
      data.DepositMemo = cloned.DepositMemo
      data.DepositDate = cloned.DepositDate
      this.removeItemInClonedArray(data.PaymentToBankID)
    }
  }

  onDetailSave(data) {
    data['IsRowEditing'] = false;
    this.removePrevDetails(data.PaymentHeaderID);

    if (data.PaymentType === 'Credit Card' || data.PaymentType === 'ACH') {
      Swal.fire({
        title: 'Confirmation',
        text: this.translate.instant('edit-payment-data-warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.mangoAPISrvc.updatePaymentHeader(data, data.PaymentHeaderID).subscribe((result: any) => {
            if(data['isPaymentDateUpdated']) {
              data["isPaymentDateUpdated"] = false;
              this.mangoAPISrvc
                .updatePaymentDetailsByPaymentHeaderID(
                  { PaymentDate: data["PaymentDate"] },
                  data["PaymentHeaderID"]
                )
                .subscribe(
                  (result: any) => {},
                  (err) => {}
                );
              this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
              this.mangoAPISrvc.showLoader(false);
            }
            this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
            this.mangoAPISrvc.showLoader(false)
          }, err => {
            this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            this.mangoAPISrvc.showLoader(false)
          })
        }
        else {
          const cloned = this.onPreviousDetails.filter(item => item.PaymentHeaderID === data.PaymentHeaderID)[0];
          data['IsRowEditing'] = false;
          data['PaymentDate'] = cloned.PaymentDate;
          this.removePrevDetails(data.PaymentHeaderID);
        }
      })
    }
    else {
      this.mangoAPISrvc.updatePaymentHeader(data, data.PaymentHeaderID).subscribe((result: any) => {
        if(data['isPaymentDateUpdated']) {
          data["isPaymentDateUpdated"] = false;
          this.mangoAPISrvc
            .updatePaymentDetailsByPaymentHeaderID(
              { PaymentDate: data["PaymentDate"] },
              data["PaymentHeaderID"]
            )
            .subscribe(
              (result: any) => {},
              (err) => {}
            );
          this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
          this.mangoAPISrvc.showLoader(false);
        }
        this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        this.mangoAPISrvc.showLoader(false)
      }, err => {
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false)
      })
    }


  }

  onDetailCancel(data) {
    const cloned = this.onPreviousDetails.filter(item => item.PaymentHeaderID === data.PaymentHeaderID)[0];
    data['IsRowEditing'] = false;
    data['PaymentDate'] = cloned.PaymentDate;
    this.removePrevDetails(data.PaymentHeaderID);
  }

  onSelectDate(rowData, type) {
    if(type === 'PaymentDate') {
      rowData['isPaymentDateUpdated'] = true
    }
  }

  onDropdownSelected(event, rowData, type) {
    if (type == "BankName") {
      event.label = this.bankList.filter((item) => item.value == event.value)[0]?.label
      rowData["BankName"] = event.label;
      rowData["BankID"] = event.value;
    }
  }

  replaceShortcuts(value, desc, type) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    desc[type] = valueArr.join(" ");
  }

  onCloseDetailsDialog() {
    this.selectedDeposit = {};
    this.detailsDatasource = [];
    this.isShowDetailsDialog = false;
  }

  editDeposit(rowData) {
    this.isShowDetailsDialog = true;
    this.selectedDeposit = rowData;
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getAllPHeaderByPaymentToBankID(rowData.PaymentToBankID).subscribe((result: any) => {
      this.detailsDatasource = result.map(itm => {
        itm['PaymentDate'] = this.mangoUtils.formatDateString(itm['PaymentDate']);
        return itm;
      });
      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      this.mangoAPISrvc.showLoader(false)
    })
  }

  onChangeBank(rowData) {
    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(rowData.PaymentDate) <= lastDayOfMonth) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), `Unable to process invoice since the month is locked.`);
      this.mangoAPISrvc.showLoader(false);
      return;
    }

    this.onChangeReassignDropdown()
    this.selectedPaymentHeader = rowData;
    this.isShowReassignDialog = true;
  }

  onCloseReassignDialog() {
    this.selectedPaymentHeader = {};
    this.isShowReassignDialog = false;
  }

  onChangeReassignDropdown() {
    const size = parseInt(this.selectedRecords);
    if (isNaN(size))
      this.filteredDepositDatasource = this.depositDatasource.filter(
        (item) => item.PaymentToBankID !== this.selectedDeposit.PaymentToBankID
      );
    else
      this.filteredDepositDatasource = this.depositDatasource
        .filter(
          (item) => item.PaymentToBankID !== this.selectedDeposit.PaymentToBankID
        )
        .slice(0, size);
  }

  onChangeDeposit() {
    Swal.fire({
      title: this.translate.instant("confirmation"),
      html:
        this.translate.instant("deposit.move_payment_to_different_deposit"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        const oldDeposit = {
          DepositAmount: this.selectedDeposit.DepositAmount
        }

        const newDeposit = {
          DepositAmount: this.selectedRadBtn.DepositAmount
        }

        const paymentAmount = numeral(this.selectedPaymentHeader.PaymentAmount).value()

        const newPaymentHeader = {
          PaymentToBankID: this.selectedRadBtn.PaymentToBankID
        }
        this.selectedDeposit.DepositAmount = oldDeposit.DepositAmount = this.mangoUtils.subtractFloat(oldDeposit.DepositAmount, paymentAmount)
        newDeposit.DepositAmount = this.mangoUtils.addFloat(newDeposit.DepositAmount, paymentAmount)

        console.log({ oldDeposit, newDeposit, newPaymentHeader })

        this.mangoAPISrvc.showLoader(true)
        forkJoin([
          this.mangoAPISrvc.updatePaymentHeader(newPaymentHeader, this.selectedPaymentHeader.PaymentHeaderID),
          this.mangoAPISrvc.updateDepositedById(this.selectedDeposit.PaymentToBankID, oldDeposit),
          this.mangoAPISrvc.updateDepositedById(this.selectedRadBtn.PaymentToBankID, newDeposit),
        ]).subscribe((result: any) => {
          this.detailsDatasource = this.detailsDatasource.filter(
            (item) =>
              item.PaymentHeaderID !== this.selectedPaymentHeader.PaymentHeaderID
          );

          this.selectedPaymentHeader = {};
          this.isShowReassignDialog = false;
          this.selectedRadBtn = null;

          if (this.detailsDatasource.length == 0)
            this.onCloseDetailsDialog()

          this.mangoAPISrvc.showLoader(false)
          this.getAllDepositsByCompanyID(this.companyID, true);
          this.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        }, err => {
          this.mangoAPISrvc.showLoader(false)
          this.mangoAPISrvc.notify('error', 'Error!', err);
        })
      }
    });
  }

  onRowDelete(rowData) {
    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(rowData.DepositDate) <= lastDayOfMonth) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), `Unable to process invoice since the month is locked.`);
      this.mangoAPISrvc.showLoader(false);
      return;
    }

    Swal.fire({
      title: this.translate.instant("confirmation"),
      html: this.translate.instant("delete.deposit"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true)
        this.mangoAPISrvc.deleteDepositedByPaymentToBankID(rowData.PaymentToBankID).subscribe((result) => {
          this.depositDatasource = this.depositDatasource.filter(
            (item) => item.PaymentToBankID !== rowData.PaymentToBankID
          );
          this.mangoAPISrvc.notify("success", "Success!", AppConstants.deleteMessage)
          this.mangoAPISrvc.showLoader(false)
        }, err => {
          this.mangoAPISrvc.notify("error", "Error!", AppConstants.deleteErrorMsg)
          this.mangoAPISrvc.showLoader(false)
        })
      }
    })
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (e != null && this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(e) <= lastDayOfMonth) {
      this.mangoAPISrvc.notify('error', "Error!", "System Locking is enabled on the selected date.");
    }
  }

  validateDepositDateTimeout: any;
  validateDepositDate( depositDate ){
    if(
        (
              typeof
              this.validateDepositDateTimeout
          !=  "undefined"
        )
    ){
      clearTimeout( this.validateDepositDateTimeout );
      this.validateDepositDateTimeout = undefined;
    }

    this.validateDepositDateTimeout = (
      setTimeout( ( ) => {
        const dateToday = (
          new Date( )
        );

        if (this.mangoUtils.dateDiff(depositDate, dateToday, "months") > 2) {
          Swal.fire(
            {
              "icon": 'warning',

              "title": (
                `${ this.translate.instant( 'Warning' ) }?`
              ),
              "text": (
                `${ this.translate.instant( 'two-month-gap-date-entry-warning' ) }!`
              ),

              "confirmButtonText": 'OK',

              "showCancelButton": false,
              "allowEscapeKey": true,
              "allowEnterKey": true,
            }
          );
        }

        clearTimeout( this.validateDepositDateTimeout );
        this.validateDepositDateTimeout = undefined;
      }, 500 )
    );
  }
}
