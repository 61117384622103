import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api';
import { AfterViewInit, Inject, Compiler, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  AuthGuard,
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils
} from "@app/_services";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import Swal from "sweetalert2";
import { IStaxEnum } from '@app/modules/accounting/s-tax/s-tax.component';
import { timer } from 'rxjs';
declare let numeral: any;

enum EnumTabValues {
  INVOICE = 0,
  PAYMENT_HEADER = 1,
  PAYMENT_DETAIL = 2,
  STAX = 3,
  SLIPS = 4
}

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html'
})
export class ManageAccountsComponent implements OnInit, AfterViewInit {
  public list: any = [];
  public selectedItem: any;
  private timer: any;
  subscription;
  public companySel: any = null;
  public companyDetails: any = {};
  public companyList: any = [];
  public filteredCompanyList: any = [];
  public plans: Array<{ label: string; value: string }> = [
    { label: 'FREE', value: 'FREE' },
    { label: 'SOLO', value: 'SOLO' },
    { label: 'PRO', value: 'PRO' },
    { label: 'ENTERPRISE', value: 'ENTERPRISE' }
  ];

  public editableTexts;
  staffDataSource: any = [];
  staffId: any = [];
  cardChanged: boolean = false;
  lastInvoiceDate;
  lastTimeRecordDate;
  showPasswordDialog: boolean = false;
  companyName: string = '';
  superAdminDeleteVisible: number[] = AppConstants.superAdminDeleteVisible;
  isShowSuperAdminTool: boolean = false;

  items: MenuItem[];
  activeItem: MenuItem;
  viewType = 0;
  invoiceOptions = []

  invoices = [];
  slips = [];
  @ViewChild("searchValueInvoice") searchValueInvoice;
  @ViewChild("dtInvoices") dtInvoices: Table;
  filteredItemsSizeInvoice = -1;
  searchTextStrInvoice: string = "";
  isShowLinkPaymentDetailDialog = false;
  selectedInvoice = null;
  isShowEditBillingHeaderDialog: boolean = false;
  selectedBillingHeader = null;
  billingHeaderObj = {
    InvoiceAmount: 0,
    InvoiceBalance: 0,
    TotalPayments: 0,
    InvoiceDate: null
  };
  @ViewChild("billingHeaderForm") billingHeaderForm;
  isBillingHeaderFormValid = false;

  paymentHeaders = [];
  @ViewChild("searchValuePaymentHeader") searchValuePaymentHeader;
  @ViewChild("dtPaymentHeader") dtPaymentHeader: Table;
  filteredItemsSizePaymentHeader = -1;
  searchTextStrPaymentHeader: string = "";
  isShowEditPaymentHeaderDialog: boolean = false;
  selectedPaymentHeader = null;
  paymentHeaderObj = {
    PaymentUnapplied: 0,
    PaymentAmount: 0,
    BillingHeaderID: null,
    PaymentDate: null
  };
  @ViewChild("paymentHeaderForm") paymentHeaderForm;
  isPaymentFormValid = false;

  paymentDetails = [];
  @ViewChild("searchValuePaymentDetail") searchValuePaymentDetail;
  @ViewChild("dtPaymentDetail") dtPaymentDetail: Table;
  filteredItemsSizePaymentDetail = -1;
  searchTextStrPaymentDetail: string = "";
  selectedPaymentDetails = [];
  isShowAddEditPaymentDetailDialog: boolean = false;
  selectedPaymentDetail = null
  paymentDetailObj = {
    BillingHeaderID: null,
    PaymentHeaderID: null,
    PaymentDate: null,
    InvoiceNumber: null,
    CheckRef: null,
    AppliedAmount: 0,
    PaymentType: "Cash",
    PaymentNote: null,
    InvoiceDate: null,
    ClientID: null,
    isAdvancePayment: false
  };
  @ViewChild("paymentDetailForm") paymentDetailForm;
  isPaymentDetailFormValid = false;
  isShowUpdateStaxEnumDialog = false
  staxEnums: IStaxEnum[] = [];
  
  @ViewChild("staxDetailForm") staxDetailForm;
  isStaxDetailFormValid = false;
  oldStaxDetailObj = {
    StaxToken: null,
    StaxMerchantID: null,
    StaxStatus: null,
    StaxMerchantAPIKey: null,
    isStaxEnrollmentStarted: null,
    StaxPublicKey: null
  }
  staxDetailObj = {
    StaxToken: null,
    StaxMerchantID: null,
    StaxStatus: null,
    StaxMerchantAPIKey: null,
    isStaxEnrollmentStarted: null,
    StaxPublicKey: null
  };

  globalSearchValue: string = ""
  dateSubscribedFlag:boolean = false;
  isFetching: boolean;
  
  constructor(
    @Inject(Compiler) private _compiler: Compiler,
    private mangoAPISrvc: MangoApiService,
    private translate: TranslateService,
    private encrDecSrvc: EncrDecrService,
    private mangoUtils: mangoUtils,
    private breadcrumbService: BreadcrumbService,
    private _router: Router,
    private zone: NgZone,
    private authGuard: AuthGuard,
    private sharedSrvc: SharedComponentsService
  ) {
    this.breadcrumbService.setItems([
      { label: 'Super Admin Dashboard' },
      { label: 'Manage Accounts', icon: 'ic-red' }
    ]);

    this.timer = timer(0.5 * 1000);

    this.editableTexts = [
      { plan: false },
      { users: false },
      { trial: false },
      { plan: false },
      { cost: false },
      { monthly: false },
      { annual: false },
      { dateCancelled: false },
      { elClientLimit: false },
    ];

    this.mangoAPISrvc.isInvoiceUpdated$.subscribe(data => {
      if (data) this.getSlips();
    });
  }

  ngOnInit() {
    this.staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.items = [
      {
        label: "Invoices",
        icon: "fal fa-file-invoice",
        command: () => {
          this.changeViewType(EnumTabValues.INVOICE);
        },
      },
      {
        label: "Payment Headers",
        icon: "fal fa-money-check-alt",
        command: () => {
          this.changeViewType(EnumTabValues.PAYMENT_HEADER);
        },
      },
      {
        label: "Payment Details",
        icon: "fal fa-money-check",
        command: () => {
          this.changeViewType(EnumTabValues.PAYMENT_DETAIL);
        },
      },
    ];
    this.mangoAPISrvc.canUpdateStax().subscribe((result: any) => {
      if(result.data.canUpdateStax) {
        this.items.push({
          label: "STAX",
          icon: "fal fa-credit-card",
          command: () => {
            this.changeViewType(EnumTabValues.STAX);
          },
        })
      }
    })
    this.subscription = this.sharedSrvc.invoiceDialogData
      .subscribe((data) => {
        if (data && this.viewType == EnumTabValues.INVOICE) {
          this.changeViewType(EnumTabValues.INVOICE);
        }
      })
  }

  ngAfterViewInit() {
    this.paymentHeaderForm.control.valueChanges.subscribe((values) => {
      this.validatePaymentHeaderForm();
    });

    this.billingHeaderForm.control.valueChanges.subscribe((values) => {
      this.validateBillingHeaderForm();
    });

    this.paymentDetailForm.control.valueChanges.subscribe((values) => {
      this.validatePaymentDetailForm();
    });
  }

  getLastInvoiceDate(id) {
    this.mangoAPISrvc.getLastInvoiceDate(id).subscribe({
      next: (data: any) => {
        this.lastInvoiceDate = data[0]?.InvoiceDate;
      },
      error: (err) => this.mangoAPISrvc.showLoader(false),
      complete: () => {
        this.handleCompanyCount++;
        if(this.handleCompanyCount == 4) {
          this.mangoAPISrvc.showLoader(false);
        }
      }
    });
  }

  getLastTimeRecordDate(id) {
    this.mangoAPISrvc.getLastTimeRecordDate(id).subscribe({
      next: (data: any) => {
        this.lastTimeRecordDate = data[0]?.Ddate;
      },
      error: (err) => this.mangoAPISrvc.showLoader(false),
      complete: () => {
        this.handleCompanyCount++;
        if(this.handleCompanyCount == 4) {
          this.mangoAPISrvc.showLoader(false);
        }
      }
    });
  }

  changeUSMoney(evt: any, data: any) {
    let enteredValue;
    if (evt['target']) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt['value'] ? evt['value'] : evt['value'];
    }
    if (enteredValue == '' && enteredValue != 0) {
      enteredValue = data;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt['target']['value'] = data = '$0.00';
      } else {
        evt.setValue('0.00');
      }
    } else {
      if (data) {
        evt['target']['value'] = data = '$' + numeral(enteredValue).format('0,0.00');
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format('0,0.00'));
      }
    }
  }

  changeUSMoneyData(str) {
    const myNumeral = numeral(str);
    if (myNumeral.value() === null) {
      if (str) {
        str = str = '$0.00';
      } else {
        str = '$0.00';
      }
    } else {
      if (str) {
        str = '$' + numeral(str).format('0,0.00');
      } else {
        if (myNumeral.value() < 0) {
          str = 0;
        }
        str = '$' + numeral(str).format('0,0.00');
      }
    }
    return str;
  }

  onLabelClick(type) {
    if (type === 'plan') this.editableTexts.plan = true;
    else if (type === 'users') this.editableTexts.users = true;
    else if (type === 'trial') {
      this.editableTexts.trial = true;
      this.companyDetails.DateSubscribed = this.companyDetails.DateSubscribed || new Date();
    } else if (type === "datePlan") {
      this.editableTexts.datePlan = true;
      this.companyDetails.DatePlanActivated = this.companyDetails.DatePlanActivated || new Date();
    } else if (type === "cost") this.editableTexts.cost = true;
    else if (type === "elClientLimit") this.editableTexts.elClientLimit = true;
    else if (type === "dateCancelled") {
      this.editableTexts.dateCancelled = true;
      this.companyDetails.DateCancelled = this.companyDetails.DateCancelled || new Date();
    }
    // else if (type === "annual") this.editableTexts.annual = true;
    else return;
  }

  onCardBlur(type) {
    if (type === 'plan') this.editableTexts.plan = false;
    else if (type === 'users') this.editableTexts.users = false;
    else if (type === 'trial') this.editableTexts.trial = false;
    else if (type === 'datePlan') this.editableTexts.datePlan = false;
    else if (type === 'cost') this.editableTexts.cost = false;
    else if (type === 'monthly') this.editableTexts.monthly = false;
    else if (type === 'annual') this.editableTexts.annual = false;
  }

  computeCost() {
    this.companyDetails.MonthlyBillingAmount =
      parseFloat(this.companyDetails.BaseSubscriptionAmount) * this.companyDetails.NumberofUsers;
    this.companyDetails.AnnualAmount = this.companyDetails.MonthlyBillingAmount * 12;
  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  handleChangedCard(evt, type) {
    if (type === 'SubscriptionLevel') {
      this.companyDetails.SubscriptionLevel = evt.value;
      this.editableTexts.plan = false;
    } else if (type === 'NumberofUsers') {
      this.editableTexts.users = false;
      this.computeCost();
    } else if (type === "DateSubscribed") {
      this.editableTexts.trial = false;
      this.dateSubscribedFlag = true;
    } else if (type === "DatePlanActivated") {
      this.editableTexts.datePlan = false;
    } else if (type === 'BaseSubscriptionAmount') {
      this.editableTexts.cost = false;
      this.computeCost();
    } else if (type === "MonthlyBillingAmount") {
      this.editableTexts.monthly = false;
    } else if (type === 'AnnualAmount') {
      this.editableTexts.annual = false;
    } else if (type === 'DateCancelled') {
      this.editableTexts.dateCancelled = false;
    } else if (type === "ELClientLimit") {
      this.editableTexts.elClientLimit = false;
    } else {
      return;
    }

    this.cardChanged = true;
  }

  onSelect(item) {}

  onResetRecords() {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant("delete_dummy_records"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        //api here
        this.mangoAPISrvc.showLoader(true);
        const companyID = this.companySel.CompanyID;
        this.mangoAPISrvc.deleteMangoData(companyID).subscribe(
          (result) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify("success", "Success!", AppConstants.deleteMessage);
          },
          (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify("error", "error!", err);
          }
        );
      }
    });
  }

  onSave() {
    const companyObj = {
      SubscriptionLevel: this.companyDetails.SubscriptionLevel,
      NumberofUsers: this.companyDetails.NumberofUsers,
      DateSubscribed : (this.dateSubscribedFlag && this.companyDetails.DateSubscribed != null) 
        ? moment.utc(this.companyDetails.DateSubscribed).add(1, "days").format("YYYY-MM-DD") 
        : this.companyDetails.DateSubscribed,        
      DatePlanActivated: this.companyDetails.DatePlanActivated
        ? this.removeOffset(this.companyDetails.DatePlanActivated)
        : this.companyDetails.DatePlanActivated,
      BaseSubscriptionAmount: parseFloat(this.companyDetails.BaseSubscriptionAmount),
      MonthlyBillingAmount: this.companyDetails.MonthlyBillingAmount,
      AnnualAmount: this.companyDetails.AnnualAmount,
      DateCancelled: this.companyDetails.DateCancelled
        ? this.mangoUtils.removeOffset(this.companyDetails.DateCancelled)
        : null,
      ELClientLimit: parseInt(this.companyDetails.ELClientLimit) ?? 0,
    };

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateSettingsByCompanyId(this.companySel.CompanyID, companyObj).subscribe(
      (data: any) => {
        this.cardChanged = false;
        this.editableTexts = [
          { plan: false },
          { users: false },
          { trial: false },
          { plan: false },
          { cost: false },
          { monthly: false },
          { annual: false },
          { dateCancelled: false },
        ];
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify("success", "Success", data.message.toString());
      },
      (error) => {
        this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      }
    );

    if (this.companyDetails.ELClientLimit > 0 && !this.companyDetails.isELConfigured) {
      this.mangoAPISrvc.copyTemplatesToCompany(this.companyDetails.CompanyID).subscribe(
        () => {
          this.mangoAPISrvc.notify(
            "success",
            this.translate.instant("Success"),
            "Successfully enabled engagement letters."
          );

          this.companyDetails.isELConfigured = true;
        },
        () => {
          this.mangoAPISrvc.notify(
            "error",
            this.translate.instant("Error"),
            "Failed to enable engagement letters."
          );
        }
      );
    }
  }

  onChange() {}

  getChangedRows() {
    return this.staffDataSource.filter(staff => staff.IsRowChanged == true);
  }

  onSaveStaff(rowData) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateUserPassword(rowData.StaffID, rowData).subscribe(
      (data) => {
        if (data.message) {
          this.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
          rowData["IsRowChanged"] = false;
        }
        this.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  onEditStaff() {}

  getManageAccountDetails(companyIdRef) {
    this.mangoAPISrvc.getManageAccounts(companyIdRef).subscribe({
      next: (data: any) => {
        this.companyDetails = data[0];
        this.companyDetails.CompanyID = companyIdRef;
        this.companyDetails.SubscriptionLevel = data[0].SubscriptionLevel;
        this.companyDetails.BaseSubscriptionAmount = data[0].BaseSubscriptionAmount
          ? data[0].BaseSubscriptionAmount.replace("$", "").replace("-", "")
          : 0.0;
        this.companyDetails.DateSubscribed = data[0].DateSubscribed
          ? new Date(data[0].DateSubscribed)
          : null;
        this.companyDetails.DatePlanActivated = data[0].DatePlanActivated
          ? new Date(data[0].DatePlanActivated)
          : null;
        this.companyDetails.DateCancelled = data[0].DateCancelled
          ? this.mangoUtils.addOffset(new Date(data[0].DateCancelled))
          : null;
        this.companyDetails.MonthlyBillingAmount =
          parseFloat(data[0].BaseSubscriptionAmount) * data[0].NumberofUsers;
        this.companyDetails.AnnualAmount =
          parseFloat(this.companyDetails.MonthlyBillingAmount) * 12;
      },
      error: err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      },
      complete: () => {
        this.handleCompanyCount++;
        if(this.handleCompanyCount == 4) {
          this.mangoAPISrvc.showLoader(false);
        }
      }
    });
  }

  private handleCompanyCount:number = 0;
  handleCompanySelect(company) {
    this.companySel = {
      CompanyID: company.CompanyID,
      CompanyName: company.CompanyName
    };
    this.companyDetails = {};
    this.cardChanged = false;
    this.handleCompanyCount=0;
    this.mangoAPISrvc.showLoader(true);
    this.getManageAccountDetails(this.companySel.CompanyID);
    this.getLastInvoiceDate(this.companySel.CompanyID);
    this.getLastTimeRecordDate(this.companySel.CompanyID);
    this.getAllUsersDataSource();
  }

  private timeoutSrch1: any = null;
  filterCompanyList(event) {
    const self = this;

    if (self.timeoutSrch1) {
      self.timeoutSrch1.unsubscribe();
    }

    self.timeoutSrch1 = self.timer.subscribe(t => {
      self.fetchCompanyList(event.query, () => {
        self.filteredCompanyList = self.companyList.sort(
          self.mangoUtils.compareValues('CompanyName', 'asc')
        );
        self.timeoutSrch1.unsubscribe();
      });
    });
  }

  onLogin(data: any) {
    localStorage.clear();
    sessionStorage.clear();
    this.mangoAPISrvc.showLoader(true);
  
    this.mangoAPISrvc.getAdminUserCredentails(data.StaffID).subscribe({
      next: (resp: any) => {
        const user = {
          email: resp.Email,
          password: resp.SystemPassword,
          isEncrypted: true,
        };
  
        this.mangoAPISrvc.login(user).subscribe({
          next: (res: any) => {
            const data = res.data;
            if (data.Inactive === true) {
              this.mangoAPISrvc.showLoader(false);
              Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "User Inactive. Login is Not Allowed.",
                showConfirmButton: false,
                timer: 4000,
              });
              return;
            }
  
            const timerTask: any = {
              isClearTimers: true,
            };
            this.sharedSrvc.timerTask = timerTask;
  
            this.encrDecSrvc.removeObject(AppConstants.timerTasks);
            this.encrDecSrvc.removeObject(AppConstants.timerOwner);
            this.encrDecSrvc.addObject(AppConstants.token, data.token);
            this.encrDecSrvc.addObject(AppConstants.isManagingAccount, true);
            this.encrDecSrvc.addObject(AppConstants.userName, data.StaffName);
            this.encrDecSrvc.addObject(AppConstants.userEmail, data.Email);
            this.encrDecSrvc.addObject(AppConstants.isLoggingUser, 'true');
            this.encrDecSrvc.addObject(AppConstants.companyID, data.CompanyID);
            this.encrDecSrvc.addObject(AppConstants.subscriptionLevel, data.subscriptionLevel);
            this.encrDecSrvc.addObject(AppConstants.resourceID, data.StaffID);
            this.encrDecSrvc.addObject(AppConstants.userJustLoggedIn, true);
            this.encrDecSrvc.addObject(AppConstants.setupStep, data.SetupStep);
            this.encrDecSrvc.addObject(AppConstants.profileIMGURL, data.ProfilePic);
            this.encrDecSrvc.addObject(AppConstants.staffID, data.StaffID);
  
            const start = moment(data.DatePlanActivated, 'YYYY-MM-DD');
            const end = moment(data.DateCancelled, 'YYYY-MM-DD');
            const noOfDays = moment.duration(end.diff(start)).asDays();
            const calculateDate = moment(end).add(noOfDays, 'd');
            this.encrDecSrvc.loadDefaultAPI();
            if (calculateDate.isBefore(moment())) {
              Swal.fire({
                title: 'Membership Expired',
                html: `<p class="padding-bottom"><strong>Your FREE trial has expired, or Plan Subscription is cancelled.</strong> To access features of Mango Practice Management you must select a Plan Option. Would you like to review Plan Options?</p>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed!',
                cancelButtonText: 'No',
                backdrop: true,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  this.redirect('subscribe');
                } else {
                  this.logout();
                }
              });
              return;
            }
  
            if (calculateDate.isAfter(moment())) {
              this.encrDecSrvc.addObject(AppConstants.dateCancelled, null);
            } else {
              if (data.DateCancelled) {
                this.encrDecSrvc.addObject(AppConstants.dateCancelled, data.DateCancelled);
              }
            }
  
            if (data.ProfilePic) {
              this.encrDecSrvc.addObject(AppConstants.imageUrl, data.ProfilePic);
            }
  
            if (data.DateSubscribed) {
              this.encrDecSrvc.addObject(AppConstants.dateSubscribed, data.DateSubscribed);
            }
            if (data.DatePlanActivated) {
              this.encrDecSrvc.addObject(AppConstants.datePlanActivated, data.DatePlanActivated);
            }
            this.encrDecSrvc.addObject(AppConstants.rememberMe, false);
  
            this.mangoAPISrvc.getProfileData().subscribe({
              next: (userData: any) => {
                userData.DateLastLogin = new Date();
                this.mangoAPISrvc.updateProfileView(userData).subscribe();
              }
            });
  
            this.mangoAPISrvc.getCompanyDetails(data.CompanyID).subscribe({
              next: (res: any) => {
                this.encrDecSrvc.addObject(AppConstants.companyName, res.CompanyName);
                this.encrDecSrvc.addObject(AppConstants.linkToPortal, res.PortalURL);
                this.encrDecSrvc.addObject(AppConstants.companyEmail, res.EmailCompany);
                this.encrDecSrvc.addObject(AppConstants.telephone, res.Telephone);
  
                this.mangoAPISrvc.loadDefaultAPI(data.StaffID).subscribe({
                  next: (results: any) => {
                    this.encrDecSrvc.removeObject('clients_active');
                    this.encrDecSrvc.removeObject('clients_inactive');
                    this.encrDecSrvc.removeObject('clients_contacts');
                    this.encrDecSrvc.removeObject('clients_all');
                    this.encrDecSrvc.addObject(AppConstants.clientCount, results[0]);
                    this.encrDecSrvc.addObject(AppConstants.clientGroupCategory, results[1]);
                    this.encrDecSrvc.addObject(AppConstants.staffList, results[2]);
                    this.encrDecSrvc.addObject(AppConstants.customerType, results[3]);
                    this.encrDecSrvc.addObject(AppConstants.shotHands, results[4]);
                    this.encrDecSrvc.addObject(AppConstants.timeAndExpenses, results[5]);
                    this.encrDecSrvc.addObject(
                      AppConstants.isStaxEnrollmentStarted,
                      results[5].isStaxEnrollmentStarted
                    );
                    this.encrDecSrvc.addObject(AppConstants.activityList, results[6]);
                    this.encrDecSrvc.addObject(AppConstants.expenses, results[7]);
                    this.encrDecSrvc.addObject(AppConstants.invoiceOptions, results[8]);
                    this.encrDecSrvc.addObject(AppConstants.noteCategories, results[9]);
                    this.encrDecSrvc.addObject(AppConstants.planInfo, results[10]);
                    this.encrDecSrvc.addObject(AppConstants.userPermissionObj, results[11]);
                    this.encrDecSrvc.addObject(AppConstants.dmsEmailTemplate, results[12]);
                    this.encrDecSrvc.addObject(AppConstants.workLocations, results[13]);
                    this.encrDecSrvc.addObject(AppConstants.userPermissions, results[15]);
  
                    this.encrDecSrvc.addObject(AppConstants.allStaffList, results[14]);
                    if (window.screen.width >= 1700 && window.screen.width < 4000) {
                      this.encrDecSrvc.addObject(AppConstants.isLaptop, false);
                    } else if (window.screen.width >= 1500 && window.screen.width < 1650) {
                      this.encrDecSrvc.addObject(AppConstants.isLaptop, false);
                    } else {
                      this.encrDecSrvc.addObject(AppConstants.isLaptop, true);
                    }
  
                    this.mangoAPISrvc.getCompanyMangoDetails(data.CompanyID).subscribe({
                      next: (Pdata: any) => {
                        this.mangoAPISrvc.showLoader(false);
                        const routeValue = results[11].StartScreen
                          ? results[11].StartScreen
                          : Pdata[0].LoadScreen
                          ? Pdata[0].LoadScreen
                          : 'DB';
  
                        this.encrDecSrvc.addObject(AppConstants.companySettingsData, Pdata[0]);
                        setTimeout(() => {
                          if (routeValue === 'DB') {
                            this._router
                              .navigate([AppConstants.dashBoardRoutePath])
                              .then(() => window.location.reload());
                          } else if (routeValue === 'CL') {
                            this._router
                              .navigate([AppConstants.clientRoutePath])
                              .then(() => window.location.reload());
                          } else if (routeValue === 'SCH') {
                            this._router
                              .navigate([AppConstants.schedulingRoutePath])
                              .then(() => window.location.reload());
                          } else if (routeValue === 'DDM') {
                            this._router
                              .navigate([AppConstants.projectManagementRoutePath])
                              .then(() => window.location.reload());
                          } else if (routeValue === 'TS') {
                            this._router
                              .navigate([AppConstants.timeExpenseRoutePath])
                              .then(() => window.location.reload());
                          }
                        }, 1000);
                      },
                      error: (error) => {
                        console.log(error);
                      }
                    });
                  }
                });
              }
            });
          },
          error: (err) => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notifyLogging(false);
            Swal.fire({
              icon: 'warning',
              title: 'Information!',
              text: 'Error',
              showConfirmButton: false,
              timer: 3000,
              position: 'top-end',
            });
          }
        });
      },
      error: () => this.mangoAPISrvc.showLoader(false)
    });
  }
  
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  onEditPassword(rowData) {
    if (rowData['SystemPassword'].length < 3) {
      rowData['IsRowChanged'] = false;
      return;
    }

    rowData['IsRowChanged'] = true;
  }

  redirect(page) {
    switch (page) {
      case 'login':
        this._router.navigate(['/login']);
        break;
      case 'subscribe':
        this._router.navigate(['/companySettings/Subscription']);
        break;
    }
  }

  getAllUsersDataSource() {
    this.mangoAPISrvc.getAllStaffListByCId(this.companySel.CompanyID).subscribe({
      next: (users: any) => {
        // this feature needs discussion
        this.staffDataSource = users.filter(
          (user) => user.Inactive != true && user.StaffID != this.staffId
        );
      },
      error: err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      },
      complete: () => {
        this.handleCompanyCount++;
        if(this.handleCompanyCount == 4) {
          this.mangoAPISrvc.showLoader(false);
        }
      }
    });
  }

  private fetchCompanyList(searchString: string, callback: any) {
    const self = this;

    this.mangoAPISrvc.getCompanyNames(`search=${searchString}`).subscribe(
      (result: any) => {
        self.companyList = result.filter((item) => item.CompanyID !== 1);

        callback();
      },

      (err) => {
        self.mangoAPISrvc.notify(
          'error',
          'Error!',
          'Error while fetching data from server. Please review the inputs.'
        );

        self.timeoutSrch1.unsubscribe();
      }
    );
  }

  onConfirmDelete() {
    if (this.companySel.CompanyName.toLowerCase() === this.companyName.toLowerCase()) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.deleteCompanyByID(this.companySel.CompanyID).subscribe(
        (result) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("success", "Success!", AppConstants.deleteMessage);
          this.companySel = {};
          this.companyDetails = {};
          this.closePasswordDialog();
        },
        (err) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("error", "Error!", AppConstants.deleteErrorMsg);
          this.closePasswordDialog();
        }
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "You have entered an incorrect company name, please try again to continue deleting this company.",
        showConfirmButton: true,
      });
    }
  }

  closePasswordDialog() {
    this.companyName = '';
    this.showPasswordDialog = false;
  }

  onExportDocumentsToIShare() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.exportDocumentsToIShare({ companyID: this.companySel?.CompanyID }).subscribe(
      (result) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "success",
          "Success!",
          "Successfully exported documents to IShare"
        );
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify("error", "Error!", "Failed to export documents to IShare");
      }
    );
  }

  onDelete() {
    if (this.companyDetails.SubscriptionLevel == "FREE") {
      Swal.fire({
        title: 'Warning',
        text: 'You are deleting a Trial Account.  Continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed!',
        cancelButtonText: 'No',
        backdrop: true,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          this.showPasswordDialog = true;
        }
      });
    } else {
      Swal.fire({
        title: 'Warning',
        text: 'You are deleting a LIVE  Account.  Continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed!',
        cancelButtonText: 'No',
        backdrop: true,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          this.showPasswordDialog = true;
        }
      });
    }
  }

  onUpdateClientContact() {
    Swal.fire({
      title: 'Warning',
      text: `Do you really want to update client contacts for ${this.companySel.CompanyID}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Proceed!',
      cancelButtonText: 'No',
      backdrop: true,
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.updateClientContacts(this.companySel.CompanyID).subscribe(
          (result) => {
            this.mangoAPISrvc.updateInvalidEmails(this.companySel.CompanyID).subscribe(
              (result2) => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
              },
              (err) => {
                this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
                this.mangoAPISrvc.showLoader(false);
              }
            );
          },
          (err) => {
            this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
            this.mangoAPISrvc.showLoader(false);
          }
        );
      }
    });
  }

  logoutAndRegister() {
    this.mangoAPISrvc.showLoader(false);
    this.authGuard.logout();
    // this._compiler.clearCache();
    setTimeout(() => {
      this.mangoAPISrvc.notifyLogging(false);
      this._router.navigate(['/register']);
    }, 100);
  }

  onShowSuperAdminTool() {
    this.isShowSuperAdminTool = true;
    this.changeViewType(EnumTabValues.INVOICE);
  }

  onHideSuperAdminTool() {
    this.isShowSuperAdminTool = false;
  }

  filterTable(dt, type: EnumTabValues) {
    const intervalId = setInterval(() => {
      if (type === EnumTabValues.INVOICE) {
        if (this.searchValueInvoice?.nativeElement) {
          clearInterval(intervalId);
          this.searchValueInvoice.nativeElement.value = this.globalSearchValue;
        }
      } else if (type === EnumTabValues.PAYMENT_HEADER) {
        if (this.searchValuePaymentHeader?.nativeElement) {
          clearInterval(intervalId);
          this.searchValuePaymentHeader.nativeElement.value = this.globalSearchValue;
        }
      } else {
        if (this.searchValuePaymentDetail?.nativeElement) {
          clearInterval(intervalId);
          this.searchValuePaymentDetail.nativeElement.value = this.globalSearchValue;
        }
      }
      if(this.globalSearchValue?.trim()?.length > 0)
        dt.filterGlobal(this.globalSearchValue, "contains");
    }, 300);
  }

  getSlips() {
    if (this.companySel?.CompanyID && !this.isFetching) {
      this.isFetching = true;
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc
        .getAllTimeSlipsByCompanyID(this.companySel?.CompanyID)
        .subscribe((result: any) => {
          if (result?.data?.invoices?.length > 0)
            this.slips = result?.data?.invoices.map(item => ({
              ...item,
              Difference: item.InvoiceAmount - item.sm,
              Remarks: item.sm == item.InvoiceAmount ? 'Matched' : 'Not matched'
            }));
          this.mangoAPISrvc.fireInvoiceUpdate(false);
          this.isFetching = false;
          this.mangoAPISrvc.showLoader(false);
        });
    }
  }

  changeViewType(viewType) {
    this.viewType = viewType;
    const companyID = this.companySel.CompanyID
    switch (viewType) {
      case EnumTabValues.INVOICE:
        this.invoices = [];
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.getAllBillingHeadersByCompanyID(companyID).subscribe((result: any) => {
          if (result?.data?.invoices?.length > 0)
            this.invoices = result?.data?.invoices.map((item) => ({
              ...item,
              Remarks: item.TotalCount > 0 ? (item.isMatched ? "Matched" : "Not matched") : "No Payment Detail",
            }));

          this.filterTable(this.dtInvoices, EnumTabValues.INVOICE)
          this.mangoAPISrvc.showLoader(false);
        });
        break;
      case EnumTabValues.PAYMENT_HEADER:
        this.paymentHeaders = [];
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.getAllPaymentHeadersByCompanyID(companyID).subscribe((result: any) => {
          if (result?.data?.paymentHeaders?.length > 0)
            this.paymentHeaders = result?.data?.paymentHeaders.map((item) => ({
              ...item,
              Remarks: item.TotalCount > 0 ? (item.isMatched ? "Matched" : "Not matched") : "No Payment Detail",
            }));

            this.filterTable(this.dtPaymentHeader, EnumTabValues.PAYMENT_HEADER)
          this.mangoAPISrvc.showLoader(false);
        });
        break;
      case EnumTabValues.PAYMENT_DETAIL:
        this.paymentDetails = [];
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.getAllPaymentDetailsByCompanyID(companyID).subscribe((result: any) => {
          if (result?.data?.paymentDetails?.length > 0)
            this.paymentDetails = result?.data?.paymentDetails;

          this.filterTable(this.dtPaymentDetail, EnumTabValues.PAYMENT_DETAIL)
          this.mangoAPISrvc.showLoader(false);
        });
        break;
      case EnumTabValues.STAX:
        this.mangoAPISrvc.showLoader(true)
        this.mangoAPISrvc.getSuperAdminCompanySettings(companyID).subscribe(
          (result) => {
            const companyResult = result[0]
            this.staxDetailObj = {
              StaxToken: companyResult?.StaxToken,
              StaxMerchantID: companyResult?.StaxMerchantID,
              StaxStatus: companyResult?.StaxStatus,
              StaxMerchantAPIKey: companyResult?.StaxMerchantAPIKey,
              isStaxEnrollmentStarted: companyResult?.isStaxEnrollmentStarted,
              StaxPublicKey: companyResult?.StaxPublicKey,
            };
            this.oldStaxDetailObj = {...this.staxDetailObj}
            this.mangoAPISrvc.showLoader(false);
            this.staxDetailForm?.control.valueChanges.subscribe((values) => {
              this.validateStaxDetailForm();
            });
          },
          (err) => this.mangoAPISrvc.showLoader(false)
        );
        break;
    }
    this.activeItem = this.items[viewType];
  }

  get tabValues(): typeof EnumTabValues {
    return EnumTabValues;
  }

  clearFilteredSize() {
    this.filteredItemsSizeInvoice = -1;
    this.filteredItemsSizePaymentHeader = -1;
    this.filteredItemsSizePaymentDetail = -1;
  }

  clearSearchFilterInvoice() {
    this.globalSearchValue = this.searchValueInvoice.nativeElement.value = this.searchTextStrInvoice = "";
    this.clearFilteredSize()
  }

  onFilterInvoice(obj) {
    this.filteredItemsSizeInvoice = obj.filteredValue.length;
  }

  clearSearchFilterPaymentHeader() {
    this.globalSearchValue = this.searchValuePaymentHeader.nativeElement.value = this.searchTextStrPaymentHeader = "";
    this.clearFilteredSize()
  }

  onFilterPaymentHeader(obj) {
    this.filteredItemsSizePaymentHeader = obj.filteredValue.length;
  }

  clearSearchFilterPaymentDetail() {
    this.globalSearchValue = this.searchValuePaymentDetail.nativeElement.value = this.searchTextStrPaymentDetail = "";
    this.clearFilteredSize()
  }

  onFilterPaymentDetail(obj) {
    this.filteredItemsSizePaymentDetail = obj.filteredValue.length;
  }

  onClickEditPaymentDetail(paymentDetail) {
    const companyID = this.companySel.CompanyID
    this.selectedPaymentDetail = paymentDetail
    this.paymentDetailObj = {...this.selectedPaymentDetail, PaymentDate: new Date(this.selectedPaymentDetail.PaymentDate)}
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc
      .getBillingHeaderByClientIDAndCompanyID(companyID, paymentDetail.ClientID)
      .subscribe((result: any) => {
        if (result?.data?.invoices?.length > 0) {
          this.invoiceOptions = result.data.invoices.map((invoice) => ({
            ...invoice,
            label: `${invoice.BillingHeaderID} - ${moment(invoice.InvoiceDate).format(
              "MM-DD-YYYY"
            )} - ${invoice.DescriptionShort} Invoice #: ${invoice.InvoiceNumber || "None"}`,
            value: invoice.BillingHeaderID,
          }));
          this.invoiceOptions.unshift({ label: 'None', value: null })
        }
        this.isShowAddEditPaymentDetailDialog = true
        this.mangoAPISrvc.showLoader(false);
      });
  }

  onClickAddToBillingHeader(paymentDetail) {}

  onClickEditPaymentHeader(paymentHeader) {
    this.isPaymentFormValid = false
    const companyID = this.companySel.CompanyID
    this.selectedPaymentHeader = paymentHeader;
    this.paymentHeaderObj = {
      PaymentUnapplied: paymentHeader.PaymentUnapplied,
      PaymentAmount: paymentHeader.PaymentAmount,
      BillingHeaderID: paymentHeader.BillingHeaderID,
      PaymentDate: new Date(paymentHeader.PaymentDate)
    };
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc
      .getBillingHeaderByClientIDAndCompanyID(companyID, paymentHeader.ClientID)
      .subscribe((result: any) => {
        if (result?.data?.invoices?.length > 0) {
          this.invoiceOptions = result.data.invoices.map((invoice) => ({
            ...invoice,
            label: `${invoice.BillingHeaderID} - ${moment(invoice.InvoiceDate).format(
              "MM-DD-YYYY"
            )} - ${invoice.DescriptionShort} Invoice #: ${invoice.InvoiceNumber || "None"}`,
            value: invoice.BillingHeaderID,
          }));
          this.invoiceOptions.unshift({ label: 'None', value: null })
        }
        this.isShowEditPaymentHeaderDialog = true;
        this.mangoAPISrvc.showLoader(false);
      });
  }

  onClickCreatePaymentDetail(paymentHeader) {
    const companyID = this.companySel.CompanyID
    this.selectedPaymentDetail = null
    this.paymentDetailObj = {
      BillingHeaderID: null,
      PaymentHeaderID: paymentHeader.PaymentHeaderID,
      PaymentDate: new Date(paymentHeader.PaymentDate),
      InvoiceNumber: null,
      CheckRef: paymentHeader.CheckRef,
      AppliedAmount: paymentHeader.PaymentAmount,
      PaymentType: paymentHeader.PaymentType,
      PaymentNote: paymentHeader.PaymentNote,
      InvoiceDate: null,
      ClientID: paymentHeader.ClientID,
      isAdvancePayment: false
    };
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc
      .getBillingHeaderByClientIDAndCompanyID(companyID, paymentHeader.ClientID)
      .subscribe((result: any) => {
        if (result?.data?.invoices?.length > 0) {
          this.invoiceOptions = result.data.invoices.map((invoice) => ({
            ...invoice,
            label: `${invoice.BillingHeaderID} - ${moment(invoice.InvoiceDate).format(
              "MM-DD-YYYY"
            )} - ${invoice.DescriptionShort} Invoice #: ${invoice.InvoiceNumber || "None"}`,
            value: invoice.BillingHeaderID,
          }));
          this.invoiceOptions.unshift({ label: 'None', value: null })
        }
        this.isShowAddEditPaymentDetailDialog = true;
        this.mangoAPISrvc.showLoader(false);
      });
  }

  onClickEditInvoice(invoice) {
    this.isBillingHeaderFormValid = false
    this.selectedBillingHeader = invoice;
    this.sharedSrvc.openInvoiceDialog({...invoice, isEditFlow: true, isSuperAdmin: true});
  }

  onClickLinkPaymentDetail(invoice) {
    const companyID = this.companySel.CompanyID
    this.selectedInvoice = invoice;
    this.selectedPaymentDetails = [];
    this.paymentDetails = [];
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getPaymentDetailByClientIDAndCompanyID(companyID, invoice.ClientID)
      .subscribe((result: any) => {
        if (result?.data?.paymentDetails?.length > 0)
          this.paymentDetails = result?.data?.paymentDetails;

        this.isShowLinkPaymentDetailDialog = true;
        this.mangoAPISrvc.showLoader(false);
      });
  }

  linkPaymentDetailsToInvoice() {
    const companyID = this.companySel.CompanyID
    const paymentDetailIDs = this.selectedPaymentDetails.map((detail) => detail.PaymentDetailID);
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .linkPaymentDetailsToBillingHeader(companyID, {
        billingHeaderID: this.selectedInvoice.BillingHeaderID,
        paymentDetailIDs,
      })
      .subscribe(
        (result) => {
          this.mangoAPISrvc.showLoader(false);
          const userLogs = {}
          userLogs['Action'] = "Super Admin - User Linked PaymentDetail record(s) to BillingHeader";
          userLogs['Description'] = `User linked PaymentDetail record(s) to ${this.selectedInvoice.BillingHeaderID} using Super admin tool: ${paymentDetailIDs.join(', ')}`;
          userLogs['Table'] = "PaymentDetail";
          this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
            console.log(err);
          });
          this.isShowLinkPaymentDetailDialog = false;
          this.mangoAPISrvc.notify(
            "success",
            "Success!",
            `Succesfully linked payment details to invoice.`
          );
        },
        (error) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("error", "error!", error);
        }
      );
  }

  validatePaymentHeaderForm() {
    this.isPaymentFormValid =
      this.paymentHeaderForm.valid &&
      this.paymentHeaderForm.dirty
  }

  validatePaymentDetailForm() {
    this.isPaymentDetailFormValid = this.paymentDetailForm.valid && this.paymentDetailForm.dirty;
  }

  validateStaxDetailForm() {
    this.isStaxDetailFormValid = this.staxDetailForm.valid && this.staxDetailForm.dirty;
  }

  validateBillingHeaderForm() {
    this.isBillingHeaderFormValid = this.billingHeaderForm.valid && this.billingHeaderForm.dirty;
  }

  onShowUpdateStaxEnums() {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getStaxEnums().subscribe((results: IStaxEnum[]) => {
      this.staxEnums = results
      this.isShowUpdateStaxEnumDialog = true
      this.mangoAPISrvc.showLoader(false)
    }, err => this.mangoAPISrvc.showLoader(false))
  }

  updateStaxEnum(event, rowData) {
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.updateStaxEnum(rowData.StaxEnumID, { Value: rowData.Value }).subscribe((result) => {
      rowData.isUpdated = false
      this.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.showLoader(false)
      this.mangoAPISrvc.notify("error", "Error!", AppConstants.updateErrorMsg);
    })
  }

  updatePaymentHeader() {
    const companyID = this.companySel.CompanyID
    console.log("Update Payment Header data", JSON.stringify(this.paymentHeaderObj));
    this.mangoAPISrvc.showLoader(true);
    const dataToUpdate = {
      BillingHeaderID: numeral(this.paymentHeaderObj.BillingHeaderID).value(),
      PaymentAmount: numeral(this.paymentHeaderObj.PaymentAmount).value(),
      PaymentUnapplied: numeral(this.paymentHeaderObj.PaymentUnapplied).value(),
      PaymentDate: moment(this.paymentHeaderObj.PaymentDate).format('YYYY-MM-DD')
    };
    this.mangoAPISrvc
      .updatePaymentHeaderSuperAdmin(
        companyID,
        this.selectedPaymentHeader.PaymentHeaderID,
        dataToUpdate
      )
      .subscribe(
        (result) => {
          this.paymentHeaders = this.paymentHeaders.map((paymentHeader) => {
            if (paymentHeader.PaymentHeaderID === this.selectedPaymentHeader.PaymentHeaderID) {
              return { ...paymentHeader, ...dataToUpdate };
            }
            return paymentHeader;
          });
          const userLogs = {}
          userLogs['Action'] = "Super Admin - User Updated a PaymentHeader";
          userLogs['Description'] = `User updated a PaymentHeader using Super admin tool: From ${JSON.stringify(
            this.selectedPaymentHeader
          )} to ${JSON.stringify({
            ...this.selectedPaymentHeader,
            ...dataToUpdate,
          })}`;
          userLogs['Table'] = "PaymentDetail";
          this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
            console.log(err);
          });
          this.selectedPaymentHeader = { ...this.selectedPaymentHeader, ...dataToUpdate };
          this.isShowEditPaymentHeaderDialog = false;
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
        },
        (error) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("error", "error!", error);
        }
      );
  }

  updateBillingHeader() {
    const companyID = this.companySel.CompanyID
    console.log("Update Billing Header data", JSON.stringify(this.billingHeaderObj));
    this.mangoAPISrvc.showLoader(true);
    const dataToUpdate = {
      InvoiceAmount: numeral(this.billingHeaderObj.InvoiceAmount).value(),
      InvoiceBalance: numeral(this.billingHeaderObj.InvoiceBalance).value(),
      TotalPayments: numeral(this.billingHeaderObj.TotalPayments).value(),
      InvoiceDate: moment(this.billingHeaderObj.InvoiceDate).format('YYYY-MM-DD')
    };
    this.mangoAPISrvc
      .updateBillingHeaderSuperAdmin(
        companyID,
        this.selectedBillingHeader.BillingHeaderID,
        dataToUpdate
      )
      .subscribe(
        (result) => {
          this.invoices = this.invoices.map((invoice) => {
            if (invoice.BillingHeaderID === this.selectedBillingHeader.BillingHeaderID) {
              return { ...invoice, ...dataToUpdate };
            }
            return invoice;
          });
          const userLogs = {}
          userLogs['Action'] = "Super Admin - User Updated a BillingHeader";
          userLogs['Description'] = `User updated a BillingHeader using Super admin tool: From ${JSON.stringify(
            this.selectedBillingHeader
          )} to ${JSON.stringify({
            ...this.selectedBillingHeader,
            ...dataToUpdate,
          })}`;
          userLogs['Table'] = "PaymentDetail";
          this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
            console.log(err);
          });
          this.selectedBillingHeader = { ...this.selectedBillingHeader, ...dataToUpdate };
          this.isShowEditBillingHeaderDialog = false;
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
        },
        (error) => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify("error", "error!", error);
        }
      );
  }

  onChangeBillingHeader(event, tabValue: EnumTabValues) {
    if(tabValue === EnumTabValues.PAYMENT_DETAIL) {
      const invoiceSelected = this.invoiceOptions.find(
        (invoice) => invoice.BillingHeaderID === event.value
      );
      this.paymentDetailObj.InvoiceDate = invoiceSelected.InvoiceDate;
      this.paymentDetailObj.InvoiceNumber = invoiceSelected.InvoiceNumber;
    }
  }

  createOrUpdatePaymentDetail() {
    const companyID = this.companySel.CompanyID
    let dataToUpdate = null
    if(this.selectedPaymentDetail) {
      // @NOTE: Update flow
      dataToUpdate = {
        PaymentDetailID: this.selectedPaymentDetail.PaymentDetailID,
        BillingHeaderID: this.paymentDetailObj.BillingHeaderID,
        InvoiceDate: this.paymentDetailObj.InvoiceDate,
        InvoiceNumber: this.paymentDetailObj.InvoiceNumber,
        isAdvancePayment: this.paymentDetailObj.isAdvancePayment,
        PaymentNote: this.paymentDetailObj.PaymentNote,
        AppliedAmount: this.paymentDetailObj.AppliedAmount,
        InitialAmount: this.paymentDetailObj.isAdvancePayment ? this.paymentDetailObj.AppliedAmount : null,
        PaymentDate: moment(this.paymentDetailObj.PaymentDate).format('YYYY-MM-DD')
      }
    } else {
      // @NOTE: Create flow
      dataToUpdate = {
        ...this.paymentDetailObj,
        PaymentDate: moment(this.paymentDetailObj.PaymentDate).format('YYYY-MM-DD')
      }
    }
    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.createOrUpdatePaymentDetailSuperAdmin(companyID, dataToUpdate).subscribe((result) => {
      this.mangoAPISrvc.showLoader(false);
      const userLogs = {};
      userLogs["Action"] = "Super Admin - User Created a PaymentDetail";
      userLogs[
        "Description"
      ] = `User created a PaymentDetail using Super admin tool: ${JSON.stringify({
        ...this.selectedPaymentDetail,
        ...dataToUpdate,
      })}`;
      userLogs["Table"] = "PaymentDetail";
      if (this.selectedPaymentDetail) {
        userLogs["Action"] = "Super Admin - User Updated a PaymentDetail";
        userLogs[
          "Description"
        ] = `User updated a PaymentDetail using Super admin tool: From ${JSON.stringify(
          this.selectedPaymentDetail
        )} to ${JSON.stringify({
          ...this.selectedPaymentDetail,
          ...dataToUpdate,
        })}`;
        this.paymentDetails = this.paymentDetails.map((paymentDetail) => {
          if (paymentDetail.PaymentDetailID === this.selectedPaymentDetail.PaymentDetailID) {
            return { ...paymentDetail, ...dataToUpdate };
          }
          return paymentDetail;
        });
        this.selectedPaymentDetail = { ...this.selectedPaymentDetail, ...dataToUpdate };
      }
      this.mangoAPISrvc.addUserLogs(userLogs).subscribe(
        () => {},
        (err) => {
          console.log(err);
        }
      );
      this.isShowAddEditPaymentDetailDialog = false;
      this.mangoAPISrvc.notify("success", "Success!", AppConstants.updateMsg);
    }, error => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify("error", "error!", error);
    })
  }

  onClickDeleteInvoice(invoice) {
    const companyID = this.companySel.CompanyID
    Swal.fire({
      title: this.translate.instant("confirmation"),
      text: this.translate.instant("delete_alert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("yes_delete"),
      cancelButtonText: this.translate.instant("no_delete"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc
          .deleteBillingHeaderSuperAdmin(companyID, invoice.BillingHeaderID)
          .subscribe(
            (data) => {
              this.invoices = this.invoices.filter(
                (item) => item.BillingHeaderID !== invoice.BillingHeaderID
              );
              const userLogs = {}
              userLogs['Action'] = "Super Admin - User Deleted a BillingHeader";
              userLogs['Description'] = `User Deleted a BillingHeader using Super admin tool: ${JSON.stringify(invoice)}`;
              userLogs['Table'] = "BillingHeader";
              this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
                console.log(err);
              });
              this.mangoAPISrvc.notify(
                "success",
                this.translate.instant("Success"),
                AppConstants.deleteMsg
              );
              this.mangoAPISrvc.showLoader(false);
            },
            (error) => {
              this.mangoAPISrvc.notify(
                "error",
                this.translate.instant("error"),
                AppConstants.deleteErrorMsg
              );
              this.mangoAPISrvc.showLoader(false);
            }
          );
      }
    });
  }

  onClickDeletePaymentHeader(paymentHeader) {
    const companyID = this.companySel.CompanyID
    Swal.fire({
      title: this.translate.instant("confirmation"),
      text: this.translate.instant("delete_alert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("yes_delete"),
      cancelButtonText: this.translate.instant("no_delete"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc
          .deletePaymentHeaderSuperAdmin(companyID, paymentHeader.PaymentHeaderID)
          .subscribe(
            (data) => {
              this.paymentHeaders = this.paymentHeaders.filter(
                (item) => item.PaymentHeaderID !== paymentHeader.PaymentHeaderID
              );
              const userLogs = {}
              userLogs['Action'] = "Super Admin - User Deleted a PaymentHeader";
              userLogs['Description'] = `User Deleted a PaymentHeader using Super admin tool: ${JSON.stringify(paymentHeader)}`;
              userLogs['Table'] = "PaymentHeader";
              this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
                console.log(err);
              });
              this.mangoAPISrvc.notify(
                "success",
                this.translate.instant("Success"),
                AppConstants.deleteMsg
              );
              this.mangoAPISrvc.showLoader(false);
            },
            (error) => {
              this.mangoAPISrvc.notify(
                "error",
                this.translate.instant("error"),
                AppConstants.deleteErrorMsg
              );
              this.mangoAPISrvc.showLoader(false);
            }
          );
      }
    });
  }

  onClickDeletePaymentDetail(paymentDetail) {
    const companyID = this.companySel.CompanyID
    Swal.fire({
      title: this.translate.instant("confirmation"),
      text: this.translate.instant("delete_alert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("yes_delete"),
      cancelButtonText: this.translate.instant("no_delete"),
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc
          .deletePaymentDetailSuperAdmin(companyID, paymentDetail.PaymentDetailID)
          .subscribe(
            (data) => {
              const userLogs = {}
              userLogs['Action'] = "Super Admin - User Deleted a PaymentDetail";
              userLogs['Description'] = `User Deleted a PaymentDetail using Super admin tool: ${JSON.stringify(paymentDetail)}`;
              userLogs['Table'] = "PaymentDetail";
              this.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
                console.log(err);
              });
              this.paymentDetails = this.paymentDetails.filter(
                (item) => item.PaymentDetailID !== paymentDetail.PaymentDetailID
              );
              this.mangoAPISrvc.notify(
                "success",
                this.translate.instant("Success"),
                AppConstants.deleteMsg
              );
              this.mangoAPISrvc.showLoader(false);
            },
            (error) => {
              this.mangoAPISrvc.notify(
                "error",
                this.translate.instant("error"),
                AppConstants.deleteErrorMsg
              );
              this.mangoAPISrvc.showLoader(false);
            }
          );
      }
    });
  }

  onChangeInputText(event) {
    this.globalSearchValue = event.target.value
  }

  updateStaxValues() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateStaxValues(this.staxDetailObj, this.companySel.CompanyID).subscribe(
      () => {
        this.mangoAPISrvc.showLoader(false);
        const userLogs = {};
        userLogs["Action"] = `Super Admin - Updated Stax values - CompanyID: ${this.companySel.CompanyID}`;
        userLogs["Description"] = `User with ID of ${
          this.staffId
        } updated stax values from ${JSON.stringify(this.oldStaxDetailObj)} to ${JSON.stringify(
          this.staxDetailObj
        )}`;
        userLogs["Table"] = "CompanyMango";
        this.mangoAPISrvc.addUserLogs(userLogs).subscribe(
          () => {},
          (err) => {
            console.log(err);
          }
        );

        this.oldStaxDetailObj = { ...this.staxDetailObj };
        this.mangoAPISrvc.notify(
          "success",
          this.translate.instant("Success"),
          AppConstants.updateMsg
        );
        this.isStaxDetailFormValid = false
      },
      (err) => {
        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("error"),
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  clearStaxValues() {
    this.staxDetailObj = {
      StaxToken: null,
      StaxMerchantID: null,
      StaxStatus: null,
      StaxMerchantAPIKey: null,
      isStaxEnrollmentStarted: null,
      StaxPublicKey: null
    }
    this.staxDetailForm.control.markAsDirty()
    this.isStaxDetailFormValid = true
  }
}
