import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Table } from 'primeng/table';
import moment from 'moment';
import { MenuItem, SelectItem } from 'primeng/api';
import swal from 'sweetalert2';
import { forkJoin, timer } from 'rxjs';

import {
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  BreadcrumbService,
  AuthGuard
} from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { environment } from '@environments/environment';
import { RRule } from 'rrule';
import FileSaver from 'file-saver';
import * as xlsx from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { SharedComponentsService } from '@app/shared/components/shared-components-service';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-project-list-tasks',
  templateUrl: './project-list-tasks.component.html'
})
export class ProjectListTasksComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchValue') searchValue;
  @ViewChild('dt') dt: Table;
  @ViewChild('p', { static: false }) paginator: Paginator;
  public taskObj = {
    TemplateName: null,
    dueDateID: null,
    statusID: null,
    statusIDs: null,
    staffId: null,
    assignedUserId: null,
    clientGroup: null,
    statusTask: null,
    dateRange: null,
    redirectStatus: null,
    filterStatus: null
  };

  public projectParamsObj = {
    TemplateName: null,
    dueDateID: null,
    statusID: null,
    statusIDs: null,
    staffId: null,
    assignedUserId: null,
    clientGroup: null,
    statusTask: null,
    dateRange: null,
    redirectStatus: null,
    filterStatus: null
  };

  public isDisregardIsCompleteInOrder: boolean = false;
  public senderObj: any = { toName: null, toEmail: null, companyName: null };
  public tagsList: any = [];
  public tagsListForFilters: any = [{ label: 'All', value: null, color: '' }];
  public companyTags;
  public todayDate = moment().format('YYYY-MM-DD');
  public ddmSettingsObj: any = {};
  public filteredClients: any[];

  public taskListDataSource: any = [];
  public projectTaskList: any = [];
  private allProjectTaskList: any = [];

  public unfilteredTasksDataSource: any = [];
  public clientListDatasource: any = [];
  public selectedTasksItems: any = [];
  copySelectedColunms: any = [];
  public AllStaffsTypes: any = [{ label: 'All', value: null }];
  public staffListBatchUpdate: any = [];
  public selTaskClient: any = { ClientID: null, ClientName: 'All' };
  public projectsList: any = [{ label: 'All', value: null }];
  public isApiloaded: boolean = false;
  public companyId;
  public staffID: any;
  public tasksCols: any[];
  public dueDateList: any = [
    { label: 'All', value: null },
    { label: 'No Due Date', value: 'noduedate' },
    { label: 'Past Due', value: 'pastdue' },
    { label: 'Due Today', value: 'duetoday' },
    { label: 'Due Tomorrow', value: 'duetomorrow' },
    { label: 'Due This Week', value: 'duethisWeek' },
    { label: 'Due Next Week', value: 'duetNextWeek' },
    { label: 'Due This Month', value: 'duethismonth' },
    { label: 'Due Next Month', value: 'duetNextMonth' },
    { label: 'Due This Year', value: 'duethisyear' },
    { label: 'Custom', value: 'custom' }
  ];
  public statusList: any = [
    // { label: "All Active", value: null, color: null },
    { label: 'Pending', value: 'Pending', color: 'Pending' },
    { label: 'In Progress', value: 'In Progress', color: 'InProgress' },
    { label: 'Ready For Review', value: 'Ready for Review', color: 'review' },
    { label: 'Completed', value: 'Completed', color: 'Completed' }
  ];

  public showOpenTasks: string = 'false';

  filteredTasksItemsSize = -1;
  searchTextStr: any = '';
  intervalid: any;
  taskButtonsDeleteList: MenuItem[];
  public showBatchUpdateTasksModal = false;
  public batchUpdateTasksFormTouched = false;
  filteredData: any;
  public globalFilterColumns: any = [];

  private fromCompleteTask: boolean = false;
  public userSelectedFilters: any[];
  groupList: SelectItem[];
  TaskListCols: Array<string> = [];
  isFilter: boolean = false;
  disableCheck: boolean = false;
  DateFrom: any = new Date();
  DateTo: any = new Date();

  @ViewChild('batchUpdateTasksForm') batchUpdateTasksForm;
  public formObj = {
    isAssigned: false,
    // staffId: null,
    staffIds: null,
    isDueDate: false,
    dueDate: null,
    isMemo: false,
    memo: null,
    updateIsRepeat: false,
    isRepeat: false
  };

  public filterProjectTaskListEventData: any = {};
  public displayFilterOption: any = {};
  public filterProjectTaskListDialog: any;
  AllManagers: any[] = [];

  cols: any[];
  _selectedColumns: any[];
  isColumnSelectedProjectName: boolean = false;
  isColumnSelectedManager: boolean = false;
  isColumnSelectedTaskDueD: boolean = false;
  isColumnSelectedInDate: boolean = false;
  isColumnSelectedDueDate: boolean = false;
  isColumnSelectedStatus: boolean = false;
  isColumnSelectedClientGroup: boolean = false;
  isColumnSelectedAssignedTo: boolean = false;
  isColumnSelectedCompanyLocation: boolean = false;
  isColumnSelectedEngagement: boolean = false;
  isColumnSelectedTags: boolean = false;
  columnMappings = {
    'TemplateName': 'isColumnSelectedProjectName',
    'Manager': 'isColumnSelectedManager',
    'compareTaskDueDate': 'isColumnSelectedTaskDueD',
    'DateReceived': 'isColumnSelectedInDate',
    'SortableDueDate': 'isColumnSelectedDueDate',
    'Status': 'isColumnSelectedStatus',
    'GroupDescriptionValues': 'isColumnSelectedClientGroup',
    'StaffNames': 'isColumnSelectedAssignedTo',
    'CompanyLocation': 'isColumnSelectedCompanyLocation',
    'EngagementName': 'isColumnSelectedEngagement',
    'Tags': 'isColumnSelectedTags'
  }
  isProcessing: boolean = false;
  isManaging: any;
  selectedRowsPerPage = 10;
  sortField: any[];

  public taskListLoadingStatus: boolean = false;
  public taskListLoadedStatus: boolean = false;

  public taskListPageIndex: number = 1;
  public taskListNextPage: number = 0;
  public taskListPageSize: number = 10;

  public projectTaskListTotalCount: number = 0;

  public taskListSearchQuery: string = '';
  public taskListSortQuery: string = '';

  public taskListSortColumnMap: object = {
    ClientName: 'ClientName',
    TaskDescription: 'TaskDescription',
    Manager: 'Manager',
    compareTaskDueDate: 'DueDate',
    GroupDescriptionValues: 'GroupDescriptionIDArray',
    Tags: 'Tags',
    CompanyLocation: 'CompanyLocation',
    SortableDueDate: 'SortableDueDate',
    Status: 'Status',
    EngagementName: 'EngagementName',
    DateReceived: 'DateReceived',
    StaffNames: 'UserAssignedIDArray',
    TemplateName: 'TemplateName'
  };

  searchInputTimeout = undefined;

  public taskTypeViewQuery: string = 'ready';

  doWhenTaskListLoadedTimeout: any | undefined = undefined;
  scrollHeight: string = '69vh';

  constructor(
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private route: ActivatedRoute,
    public mangoUtils: mangoUtils,
    private http: HttpClient,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private translate: TranslateService,
    private sharedService: SharedComponentsService,
    private auth: AuthGuard
  ) {
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.getProjectNames(this.companyId);
    this.getStaffList();

    const queryParameterData = this.route.snapshot.queryParams || undefined;

    if (queryParameterData) {
      if (queryParameterData['StaffID']) {
        this.projectParamsObj.assignedUserId = parseInt(queryParameterData['StaffID']);
      }

      if (queryParameterData['statusIDs']) {
        this.projectParamsObj.statusIDs =
          typeof queryParameterData['statusIDs'] === 'string'
            ? [queryParameterData['statusIDs']]
            : queryParameterData['statusIDs'];
      }

      if (queryParameterData['Status']) {
        this.projectParamsObj.statusID = queryParameterData['Status'];
      }

      if (queryParameterData['StatusTask']) {
        if (['Ready', 'Active', 'Pending'].includes(queryParameterData['StatusTask']) !== true) {
          this.projectParamsObj.statusTask = encodeURIComponent(queryParameterData['StatusTask']);
        } else {
          this.projectParamsObj.statusTask = queryParameterData['StatusTask'];
        }
      }

      if (queryParameterData['Due']) {
        this.projectParamsObj.dueDateID = queryParameterData['Due'];
      }

      if (queryParameterData['DateRange']) {
        this.projectParamsObj.dateRange = queryParameterData['DateRange'];
      }

      if (
        'redirectStatus' in queryParameterData === true &&
        (queryParameterData.redirectStatus === true || queryParameterData.redirectStatus === 'true')
      ) {
        this.projectParamsObj.redirectStatus = true;
      }

      if (
        'filterStatus' in queryParameterData === true &&
        (queryParameterData.filterStatus === false || queryParameterData.filterStatus === 'false')
      ) {
        this.projectParamsObj.filterStatus = false;
      }
    }

    this.searchTextStr = this.encrDecSrvc.getObject('projListTasks_' + AppConstants.SearchString);
    this.taskListSearchQuery = this.searchTextStr || '';

    this.filterDataTable();

    this.fetchDdmSettings();

    this.getAllStaffList();

    this.encrDecSrvc.removeObject(AppConstants.fromCompanyTemplate);

    this.getGroups();
  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getGroups().subscribe(
      (data: any) => {
        this.groupList = data.map(group => {
          return {
            label: group.GroupDescription,
            value: group.CustomerGroupCategoryID
          };
        });
        this.groupList.unshift({ label: 'All', value: null });
        this.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  initializeColumns() {
    const self = this;

    let selectedCols = [];

    const allCols = [
      {
        field: 'Manager',
        header: this.translate.instant('Manager'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'compareTaskDueDate',
        header: this.translate.instant('Task-Due-Date'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'TemplateName',
        header: this.translate.instant('Project-Name'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'DateReceived',
        header: this.translate.instant('In-Date'),
        rowClass: 'width-4p p-text-left'
      },
      {
        field: 'SortableDueDate',
        header: this.translate.instant('due-date'),
        rowClass: 'width-4p p-text-left'
      },

      {
        field: 'Status',
        header: this.translate.instant('status'),
        rowClass: 'width-5p p-text-left'
      },

      {
        field: 'GroupDescriptionValues',
        header: this.translate.instant('Client_Groups'),
        rowClass: 'width-6p p-text-left p-text-nowrap p-text-truncate'
      },

      {
        field: 'StaffNames',
        header: this.translate.instant('assigned_to'),
        rowClass: 'width-5p p-text-left'
      },
      {
        field: 'CompanyLocation',
        header: this.translate.instant('company_location'),
        rowClass: 'width-7p p-text-left'
      },
      {
        field: 'EngagementName',
        header: this.translate.instant('engagement'),
        rowClass: 'width-5p p-text-left'
      },
      { field: 'Tags', header: this.translate.instant('Tags'), rowClass: 'width-15p p-text-left' }
    ];

    const defaultCols = [
      {
        field: 'compareTaskDueDate',
        header: this.translate.instant('Task-Due-Date'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'TemplateName',
        header: this.translate.instant('Project-Name'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'DateReceived',
        header: this.translate.instant('In-Date'),
        rowClass: 'width-4p p-text-left'
      },
      {
        field: 'SortableDueDate',
        header: this.translate.instant('due-date'),
        rowClass: 'width-4p p-text-left'
      },

      {
        field: 'Status',
        header: this.translate.instant('status'),
        rowClass: 'width-5p p-text-left'
      },

      {
        field: 'GroupDescriptionValues',
        header: this.translate.instant('Client_Groups'),
        rowClass: 'width-6p p-text-left p-text-nowrap p-text-truncate'
      },

      {
        field: 'StaffNames',
        header: this.translate.instant('assigned_to'),
        rowClass: 'width-5p p-text-left'
      },
      {
        field: 'CompanyLocation',
        header: this.translate.instant('company_location'),
        rowClass: 'width-7p p-text-left p-text-nowrap p-text-truncate'
      },
      {
        field: 'EngagementName',
        header: this.translate.instant('engagement'),
        rowClass: 'width-5p p-text-left'
      }
    ];

    this.cols = [...allCols].filter(columnData => {
      if (!this.ddmSettingsObj.isBudgetingProjectLevel && columnData.field === 'EngagementName') {
        return false;
      }

      return true;
    });

    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.TaskListCols?.length > 0) {
            selectedCols = allCols.filter(col => data.TaskListCols.includes(col.field));
            this.TaskListCols = data.TaskListCols || [];
          } else {
            selectedCols = [];
          }
          this.selectedRowsPerPage = data.TaskListDefaultPagination || 10;
          this.taskListPageSize = this.selectedRowsPerPage;

          this._selectedColumns = selectedCols.filter(columnData => {
            if (
              !self.ddmSettingsObj.isBudgetingProjectLevel &&
              columnData.field === 'EngagementName'
            ) {
              return false;
            }

            return true;
          });

          this.globalFilterColumns = [
            ...['ClientName', 'TemplateName', 'TaskDescription', 'TaskRow'],
            ...this._selectedColumns.map(col => col.field)
          ];
          if (this.TaskListCols?.length > 0) {
            this._selectedColumns = this.mangoUtils.mapOrder(
              this._selectedColumns,
              this.TaskListCols,
              'field'
            );
          }

          Object.keys(this.columnMappings).forEach(field => {
            const property = this.columnMappings[field];
            this[property] = this.selectedColumns.some(col => col.field === field);
          });


          this.doWhenTaskListLoaded(() => {
            this.getTaskList(this);
          });
        },
        err => {
          selectedCols = [...defaultCols].filter(columnData => {
            if (
              !self.ddmSettingsObj.isBudgetingProjectLevel &&
              columnData.field === 'EngagementName'
            ) {
              return false;
            }

            return true;
          });

          this.globalFilterColumns = [
            ...['ClientName', 'TemplateName', 'TaskDescription', 'TaskRow'],
            ...selectedCols.map(col => col.field)
          ];
        }
      );
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.globalFilterColumns = [
      ...['ClientName', 'TemplateName', 'TaskDescription', 'TaskRow'],
      ...this._selectedColumns.map(col => col.field)
    ];

    this._selectedColumns = this.mangoUtils.mapOrder(
      this._selectedColumns,
      this.TaskListCols,
      'field'
    );

    this.saveSelectedCols(this._selectedColumns);
  }

  handleColumn(event: any) {
    const selectedCols = event.value;

    if (selectedCols.length === 0) {
      const templateColumn = this.cols.find(col => col.field === 'TemplateName');
      this.selectedColumns = [templateColumn];
    } else {
      this.selectedColumns = selectedCols;
    }
  }
  
  

  checkDueDate(obj) {
    const dueDate = obj.ExtensionDate || obj.DueDate;
    return dueDate && dueDate < this.todayDate;
  }

  findTagByValue(tagValue, property) {
    const temp = this.tagsList.filter(item => item['value'] == tagValue);
    return temp[0] ? temp[0][property] : '';
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Project-Management') },
      { label: this.translate.instant('Project-List') },
      { label: this.translate.instant('Tasks'), icon: 'ic-red' }
    ]);

    this.taskButtonsDeleteList = [
      {
        label: this.translate.instant('delete_selected_items'),
        icon: 'fal fa-trash-alt',
        command: () => {
          this.deleteTaskItems();
        }
      },
      {
        label: this.translate.instant('pm.Batch_Update_Tasks'),
        icon: 'fal fa-pencil',
        command: () => {
          this.openBatchUpdateTasks();
        }
      }
    ];

    this.initializeColumns();

    this.initializeFilterValues();
  }

  ngOnInit(): void {
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);

    this.filterProjectTaskListDialog = this.sharedService.filterProjectTaskListDialog.subscribe(
      eventData => {
        this.filterProjectTaskListEventData = eventData;
        this.displayFilterOption = eventData;
      }
    );

    this.DateTo.setDate(this.DateTo.getDate() + 1); //date tomorrow
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
    this.sortField = this.encrDecSrvc.getObject('projListTasks_sortField') ?? [
      { field: 'ClientName', order: 1 }
    ];
  }

  getStaffList() {
    const _this = this;

    _this.mangoAPISrvc.showLoader(true);

    _this.mangoAPISrvc.getPMAllStaffList().subscribe(
      (item: any) => {
        for (let i = 0; i < item.length; ++i) {
          if (item[i].Inactive) {
            continue;
          }

          const obj = {};

          obj['label'] = item[i]['StaffName'];
          obj['value'] = item[i]['StaffID'];
          obj['StaffID'] = item[i]['StaffID'];
          obj['StaffName'] = item[i]['StaffName'];
          obj['StaffNumber'] = item[i]['StaffNumber'];
          obj['Email'] = item[i]['Email'];

          this.AllManagers.push(obj);
        }

        _this.mangoAPISrvc.showLoader(false);
      },
      err => {
        _this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  deleteTaskItems() {
    const self = this;
    event.stopPropagation();
    const observableBatch = [];
    const observableProjectHeaderBatch = [];
    const observableUpdateBatch = {};
    self.selectedTasksItems.forEach((selectedItem, key) => {
      observableBatch.push(selectedItem.ProjectDetailsID);
      const totalDeletedTasks = self.selectedTasksItems.filter(
        itemData => itemData['ProjectHeaderID'] == selectedItem['ProjectHeaderID']
      );

      if (totalDeletedTasks && totalDeletedTasks.length > 0) {
        observableUpdateBatch[selectedItem['ProjectHeaderID']] =
          totalDeletedTasks[0]['TotalTasks'] - totalDeletedTasks.length;
      }
    });
    swal
      .fire({
        title: self.translate.instant('confirmation'),
        text: self.translate.instant('pm.do-you-want-to-delete-this-record'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: self.translate.instant('yes_delete'),
        cancelButtonText: self.translate.instant('no_delete')
      })
      .then(result => {
        if (result.value) {
          self.bulkTaskDelete().subscribe(data => {
            self.bulkProjectHeaderUpdate(observableUpdateBatch).subscribe(updatedata => {
              const logdata = {};
              logdata['Action'] = 'Deleted Task/s';
              logdata['Description'] =
                'Task: ' +
                observableBatch.join(', ') +
                ' Client(s): ' +
                self.selectedTasksItems
                  ?.map(x => {
                    return x['ClientName'];
                  })
                  .join(', ');
              logdata['Table'] = '';
              if (!self.isManaging) {
                self.mangoAPISrvc.addUserLogs(logdata).subscribe(
                  res => {},
                  err => {
                    console.debug(err);
                  }
                );
              }

              for (let deleteIndex = 0; deleteIndex < observableBatch.length; deleteIndex++) {
                const element = observableBatch[deleteIndex];

                const index = self.projectTaskList.findIndex(
                  item => item.ProjectDetailsID == element
                );

                self.projectTaskList.splice(index, 1);
              }
              // update remaining elements (project Header) with  TotalTasks/TaskRow

              for (let index = 0; index < Object.keys(observableUpdateBatch).length; index++) {
                const element = Object.keys(observableUpdateBatch)[index];

                const remainingTasks = self.projectTaskList.filter(
                  itemData => itemData['ProjectHeaderID'] == element
                );
                if (remainingTasks && remainingTasks.length > 0) {
                  for (
                    let remainingIndex = 0;
                    remainingIndex < remainingTasks.length;
                    remainingIndex++
                  ) {
                    const item = remainingTasks[remainingIndex];
                    const obj = {};
                    item['TotalTasks'] = observableUpdateBatch[element];
                    item['TaskRow'] = remainingIndex + 1;
                    obj['TotalTasks'] = item['TotalTasks'];
                    obj['TaskRow'] = item['TaskRow'];
                    obj['ProjectHeaderID'] = element;
                    obj['ProjectDetailsID'] = item.ProjectDetailsID;
                    observableProjectHeaderBatch.push(obj);
                  }
                }
              }
              if (observableProjectHeaderBatch.length > 0) {
                self.bulkProjectHeaderTable(observableProjectHeaderBatch).subscribe(updatedata => {
                  self.searchTextStr = '';
                  this.encrDecSrvc.addObject('projListTasks_' + AppConstants.SearchString, '');

                  self.filterDataTable();
                  self.selectedTasksItems = [];
                  self.mangoAPISrvc.notify('success', 'Deleted!', 'Success fully deleted');
                });
                self
                  .bulkProjectDetailTable(observableProjectHeaderBatch)
                  .subscribe(updatedata => {});
              } else {
                self.searchTextStr = '';
                this.encrDecSrvc.addObject('projListTasks_' + AppConstants.SearchString, '');
                self.filterDataTable();
                self.selectedTasksItems = [];
                swal.fire({
                  icon: 'success',
                  title: `${this.translate.instant('deleted')}!`,
                  text: AppConstants.deleteMessage,
                  showConfirmButton: false,
                  timer: 1500
                });
              }
            });
          });
        }
      });
  }

  openBatchUpdateTasks() {
    this.showBatchUpdateTasksModal = true;
  }

  closeBatchUpdateTasksModal() {
    this.showBatchUpdateTasksModal = false;
    this.batchUpdateTasksFormTouched = false;
    this.formObj = {
      isAssigned: false,
      staffIds: null,
      isDueDate: false,
      dueDate: null,
      isMemo: false,
      memo: null,
      updateIsRepeat: false,
      isRepeat: false
    };
  }

  emailSendTaskToAssignedUsers(selectedTasks) {
    if (this.ddmSettingsObj.IsEmailTaskAssigned == true) {
      if (selectedTasks.length > 0) {
        selectedTasks.forEach(task => {
          const emails = this.AllStaffsTypes.filter(item =>
            task['UserAssignedIDArray']?.includes(item['value'])
          )?.map(item => item['Email']);
          this.sendAnEmail(emails, 67, null, task);
        });
      }
    }
  }

  batchUpdateTasks() {
    this.showBatchUpdateTasksModal = false;
    const observableBatch = [];
    const formObj = this.formObj;
    this.selectedTasksItems.forEach((selectedItem, key) => {
      selectedItem['isTask'] = 'T';
      selectedItem['ClientID'] = selectedItem['ClientID'] != '' ? selectedItem['ClientID'] : null;
      if (formObj.isAssigned && formObj.staffIds?.length > 0) {
        selectedItem['UserAssignedID'] = formObj.staffIds[0];
        selectedItem['UserAssignedIDArray'] = formObj.staffIds;
      }
      if (formObj.isDueDate) {
        selectedItem['DueDate'] = formObj.dueDate;
      }
      if (formObj.isMemo && formObj.memo) {
        selectedItem['TaskDescription'] = formObj.memo;
      }
      if (formObj.updateIsRepeat) {
        selectedItem['IsRepeatTask'] = formObj.isRepeat;
      }
      if (selectedItem['ProjectDetailsID']) {
        observableBatch.push(selectedItem);
      }
    });
    this.mangoAPISrvc.showLoader(true);
    const updated = Object.keys(formObj)
      .filter(k => formObj[k] === true)
      .join(', ');

    this.mangoAPISrvc.bulkUpdateBulkProjectDetails({ observableBatch }).subscribe(
      (result: any) => {
        const count = result.data;
        if (formObj.isAssigned && formObj.staffIds?.length > 0) {
          this.emailSendTaskToAssignedUsers(this.selectedTasksItems);
        }
        this.mangoAPISrvc.showLoader(false);
        if (count > 0) {
          this.doWhenTaskListLoaded(() => {
            this.getTaskList(this);
          });

          const logdata = {};
          logdata['Action'] = 'Batch Update Tasks';
          logdata['Description'] =
            'Task: ' +
            observableBatch
              .map(x => {
                return x.ProjectHeaderID;
              })
              .join(', ') +
            ' Client(s): ' +
            observableBatch
              .map(x => {
                return x['ClientName'];
              })
              .join(', ') +
            (updated ? ' Updated: ' + updated : '');
          logdata['Table'] = '';
          if (!this.isManaging) {
            this.mangoAPISrvc.addUserLogs(logdata).subscribe(
              res => {},
              err => {
                console.debug(err);
              }
            );
          }
        }
        Swal.fire({
          icon: 'success',
          title: `${this.translate.instant('Success')}!`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: `There were ${count} tasks updated!`
        });
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', 'Error', AppConstants.updateErrorMsg);
      }
    );
  }

  bulkProjectDetailTable(list) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    list.forEach((selectedItem, index) => {
      observableBatch.push(
        this.http.put(
          `${environment.API_URL}/ddm/projectDetails/update/${selectedItem.ProjectDetailsID}`,
          selectedItem,
          { headers: headers }
        )
      );
    });
    return forkJoin(observableBatch);
  }

  generateRules(date: any, currentRuleStr: string, isNextDueDate?: boolean) {
    let ruledateSource = null;
    let ruleTwodateSource = null;
    let rule = null;
    if (currentRuleStr == '' || !currentRuleStr) {
      ruledateSource = null;
      ruleTwodateSource = null;
      return false;
    }
    const dateStart: any = date ? moment(date).format('YYYYMMDDThhmmss') : null;

    rule = RRule.fromString(dateStart ? `${currentRuleStr};DTSTART=${dateStart}` : currentRuleStr);
    rule.noCache = true;
    if (!isNextDueDate || this.compareDates(date, rule.all()[0])) {
      ruleTwodateSource = rule.all()[1] ? this.convertUTCDate(rule.all()[1]) : null;
      ruleTwodateSource = ruleTwodateSource
        ? this.getNearestWeekday(ruleTwodateSource, currentRuleStr)
        : null;
    } else {
      ruleTwodateSource = rule.all()[0] ? this.convertUTCDate(rule.all()[0]) : null;
      ruleTwodateSource = ruleTwodateSource
        ? this.getNearestWeekday(ruleTwodateSource, currentRuleStr)
        : null;
    }

    return ruleTwodateSource ? new Date(ruleTwodateSource) : null;
  }

  compareDates(date1, date2) {
    return moment(date1).format('YYYY/MM/DD') == moment(date2).format('YYYY/MM/DD');
  }

  getNearestWeekday(dateString: string, currentRuleStr: string) {
    const dateMoment = moment(dateString?.substring(0, 8));
    if (
      (currentRuleStr?.toLowerCase()?.indexOf('freq=yearly') == -1 &&
        currentRuleStr?.toLowerCase()?.indexOf('freq=monthly') == -1) ||
      currentRuleStr?.toLowerCase()?.indexOf('byday=') > -1
    )
      return dateMoment.format('YYYY/MM/DD');

    if (dateMoment.format('dddd') === 'Sunday' || dateMoment.format('dddd') === 'Saturday') {
      dateMoment.add(1, 'days');
      return this.getNearestWeekday(dateMoment.format('YYYYMMDD'), currentRuleStr);
    } else return dateMoment.format('YYYY/MM/DD');
  }

  convertUTCDate(date) {
    const check = moment(date, 'YYYY/MM/DD');
    const month = parseInt(check.format('M'));
    const day = parseInt(check.format('D'));
    const year = parseInt(check.format('YYYY'));
    return new RRule({
      dtstart: new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
    })
      .toString()
      .split('DTSTART:')[1];
  }

  onChecked(event, item, oldindex) {
    const parent = this;
    parent.disableCheck = true;
    parent.fromCompleteTask = true;
    //added to disable multiple click
    if (parent.isProcessing) return;
    parent.isProcessing = true;

    if (item.IsCompleteInOrder && !item.IsTaskReady) {
      parent.mangoAPISrvc.notify('warn', 'Warning!', 'Tasks must be completed in order.');
      return false;
    }
    item.CompletionDate = moment().format('MM/DD/YYYY HH:mm:ss');
    item.IsCompleted = true;
    item.CompletedByStaffID = parent.staffID;
    this.mangoAPISrvc
      .fetchprojectDetailsByHeaderId(item['ProjectHeaderID'])
      .subscribe((responseData: any) => {
        const taskList = responseData.taskList;
        const resultTasks = taskList;

        const openTasks = resultTasks?.filter(
          taskResult =>
            !taskResult['IsCompleted'] &&
            taskResult['TaskRow'] != item['TaskRow'] &&
            taskResult['ProjectDetailsID'] != item['ProjectDetailsID']
        );

        if (openTasks.length == 0) {
          // last item, create new project??
          if (
            item.Repeat != null &&
            item.Repeat != '' &&
            item.Repeat != 'none' &&
            item.isReviewRequired != true
          ) {
            parent.mangoAPISrvc.fetchGetProjectHeader(item.ProjectHeaderID).subscribe(
              (responseItem: any) => {
                parent.isProcessing = false;
                const obj = responseItem[0];
                obj['TasksCompleted'] = 0;
                obj['TotalNotes'] = 0;
                obj['FinishDate'] = null;
                obj['PreviousDueDate'] = obj['DueDate'];
                obj['NextDueDateForTemplate'] = obj['NextDueDate'];
                parent.calculateNewRepeatData(obj);
                if (
                  obj.Repeat !== null &&
                  obj.Repeat !== '' &&
                  obj.Repeat !== 'none' &&
                  obj.Repeat !== 'custom'
                ) {
                  if (obj['isPreviousPeriodYearPolicy']) {
                    obj['TemplateName'] = obj['TemplateWildcards']
                      ? parent.mangoUtils.replacePMCaretTemplate(
                          obj['TemplateWildcards'],
                          obj.DueDate,
                          obj.isPreviousPeriodYearPolicy,
                          obj.Repeat
                        )
                      : obj['TemplateName'];
                  } else {
                    obj['TemplateName'] =
                      obj['TemplateWildcards'] && obj.NextDueDateForTemplate
                        ? parent.mangoUtils.replacePMCaretTemplate(
                            obj['TemplateWildcards'],
                            obj.NextDueDateForTemplate
                          )
                        : obj['TemplateName'];
                  }
                }

                if (obj.isReviewRequired == false && obj.Repeat == 'custom' && obj.RuleString) {
                  obj['NextDueDate'] = parent.generateRules(obj['DueDate'], obj.RuleString, true);
                  if (!obj['NextDueDate'] || !obj['DueDate']) {
                    obj['NextDueDate'] = null;
                    obj['Repeat'] = null;
                    obj['RuleString'] = null;
                  }
                }
                const oldProjectHeaderID = obj.ProjectHeaderID;
                delete obj.ProjectHeaderID;
                obj['ExtensionDate'] = null;
                parent.mangoAPISrvc.createProjectHeader(obj).subscribe((createItem: any) => {
                  if (!resultTasks || resultTasks.length == 0) {
                    parent.mangoAPISrvc
                      .copyBudgetsToNewProject({
                        oldProjectHeaderID: oldProjectHeaderID,
                        newProjectHeaderID: createItem.data['ProjectHeaderID'],
                        companyID: parent.companyId,
                        newProjectYear: moment(createItem.data['DueDate']).format('YYYY')
                      })
                      .subscribe(
                        result => {},
                        err => {}
                      );
                  }
                  parent.batchCreateAllTasks(resultTasks, createItem.data).subscribe(data => {
                    if (resultTasks?.length > 0) {
                      parent.mangoAPISrvc
                        .copyBudgetsToNewProject({
                          oldProjectHeaderID: oldProjectHeaderID,
                          newProjectHeaderID: createItem.data['ProjectHeaderID'],
                          companyID: parent.companyId,
                          newProjectYear: moment(createItem.data['DueDate']).format('YYYY')
                        })
                        .subscribe(
                          result => {},
                          err => {}
                        );
                    }

                    this.doWhenTaskListLoaded(() => {
                      this.getTaskList(true);
                    });

                    parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
                  });
                });
              },
              error => {}
            );
          }
        } else {
          parent.isProcessing = false;
        }

        const totalValidTasks = this.unfilteredTasksDataSource.filter(
          task =>
            task.ProjectDetailsID !== null &&
            task.ProjectDetailsID !== undefined &&
            task.ProjectHeaderID == item.ProjectHeaderID
        );
        const totalProcessedTasks = totalValidTasks.filter(
          details =>
            details['IsCompleted'] == true && details['ProjectHeaderID'] == item.ProjectHeaderID
        ).length;
        //completion of projects where the task belongs
        const obj = {};
        obj['TasksCompleted'] = item['TasksCompleted'] + 1;
        obj['Status'] = this.getStatus(totalValidTasks, totalProcessedTasks, item);
        /* obj["Status"] =
            openTasks.length == 0 &&
            obj['TasksCompleted'] == item.TotalTasks
              ? "Completed"
              : "In Progress"; */
        obj['isCompleted'] = obj['Status'] == 'Completed' ? true : false; //openTasks.length == 0 && obj['TasksCompleted'] == item.TotalTasks
        obj['CompletionDate'] =
          openTasks.length == 0 && obj['TasksCompleted'] == item.TotalTasks
            ? moment(new Date()).format('MM/DD/YYYY HH:mm:ss')
            : item['CompletionDate'];
        parent.mangoAPISrvc.updateProjectHeader(item.ProjectHeaderID, obj).subscribe(
          data => {
            if (parent.searchTextStr != '') {
              parent.filterDataTable();
            }
          },
          error => {}
        );

        let nextRecord = null;
        //find next ready task
        if (openTasks.length > 0 && this.ddmSettingsObj.IsEmailTaskReady == true) {
          nextRecord = openTasks.find(openTask => openTask['TaskRow'] == item['TaskRow'] + 1);
          nextRecord = nextRecord ? nextRecord : openTasks[0]; // if there are no more next tasks, find other open tasks

          if (nextRecord && nextRecord.IsCompleted != true) {
            item['IsTaskAssignedSent'] = true;
            nextRecord['Status'] = 'In Progress';
            if (nextRecord['ProjectHeaderID'] == item['ProjectHeaderID']) {
              nextRecord['isRadioDisabled'] = false;
              nextRecord['IsTaskReady'] = true;
            }
            // let emails = this.AllStaffsTypes.filter(
            //   (item) =>  nextRecord["UserAssignedIDArray"] && nextRecord["UserAssignedIDArray"]?.includes(item["value"])
            // ).map(staff=>staff['Email']);
            // if (emails) {
            //   this.sendAnEmail(emails, 36, nextRecord, item);
            // }
            this.sendEmailToNextUserAssigned(item.ProjectDetailsID, 36);
          }
        }

        //completing selected task row
        item['Status'] = 'Completed';
        item['isTask'] = 'T';
        const detailsObj = { ...item };
        delete detailsObj['DueDate'];
        parent.mangoAPISrvc.updateProjectDetails(item.ProjectDetailsID, detailsObj).subscribe(
          () => {
            taskList.sort((a, b) => a.TaskRow - b.TaskRow);
            const currentIndex = taskList.findIndex(task => task.TaskRow === detailsObj.TaskRow);

            // Check if the current task is completed
            if (detailsObj.IsCompleted) {
              // If the current task is completed, mark the next task as ready (IsTaskReady)
              const nextTask = taskList[currentIndex + 1];
              if (nextTask) {
                nextTask.IsTaskReady = true;

                // Update the next task with the new IsTaskReady status
                parent.mangoAPISrvc
                  .updateProjectDetails(nextTask.ProjectDetailsID, nextTask)
                  .subscribe(
                    () => {
                      console.log('Next task updated successfully');
                    },
                    nextTaskError => {
                      console.error('Failed to update the next task', nextTaskError);
                    }
                  );
              }
            }

            if (nextRecord) {
              parent.mangoAPISrvc
                .updateProjectDetails(nextRecord.ProjectDetailsID, nextRecord)
                .subscribe(data => {
                  this.doWhenTaskListLoaded(() => {
                    this.getTaskList(true);
                  });
                });
            } else {
              this.doWhenTaskListLoaded(() => {
                this.getTaskList(true);
              });
            }

            // Log the task completion
            let logdata = {
              Action: 'Completed Task',
              Description: `Task: ${item.ProjectDetailsID} Client: ${item.ClientName}`,
              Table: ''
            };
            parent.disableCheck = false;
            if (!parent.isManaging) {
              parent.mangoAPISrvc.addUserLogs(logdata).subscribe(
                res => {},
                err => {
                  console.debug('Failed to log the action', err);
                }
              );
            }
          },
          error => {
            console.error('Failed to update the current task', error);
          }
        );
      });
  }

  getStatus(totalValidTasks, totalProcessedTasks, ProjectDetails): string {
    let status: string = 'Pending';
    if (totalProcessedTasks > 0 && totalValidTasks.length > totalProcessedTasks) {
      status = 'In Progress';
    } else if (totalProcessedTasks > 0 && totalValidTasks.length == totalProcessedTasks) {
      if (ProjectDetails.isReviewRequired == true && ProjectDetails.Status !== 'Completed') {
        status = 'Ready for Review';
      } else {
        status = 'Completed';
      }
    } else if (totalProcessedTasks == 0) {
      status = 'Pending';
    } else if (ProjectDetails.Status == 'Completed') {
      status = 'Completed';
    }
    if (
      ProjectDetails.Status == 'Ready for Review' &&
      totalValidTasks.length == totalProcessedTasks
    ) {
      status = 'Ready for Review';
    }

    return status;
  }

  sendEmailToNextUserAssigned(projectDetailsID, templateID) {
    this.mangoAPISrvc.sendEmailToNextUserAssigned({ projectDetailsID, templateID }).subscribe(
      (result: any) => {},
      error => {
        console.error(error);
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          'Failed to send email to next assigned user'
        );
      }
    );
  }

  async sendAnEmail(email, templeteId, isToRequired, task) {
    const parent = this;

    if (!task.ProjectHeaderID) return;
    const sendInBlueObj = {
      sender: { name: 'Mango Billing', email: environment.EMAIL_RETURN_SENDER },
      to: [],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: null,
      params: {}
    };

    if (isToRequired) {
      const userObj = parent.AllStaffsTypes.filter(
        item => item['value'] == task['UserAssignedID']
      )[0];
      if (userObj) {
        const obj = { email: '', name: '' };
        obj['email'] = userObj['Email'];
        obj['name'] = userObj['label'];
        sendInBlueObj.to.push(obj);
      }
    }

    sendInBlueObj.params['taskduedate'] = task.taskduedate
      ? moment(task.taskduedate).format('MM-DD-YYYY')
      : '-';
    sendInBlueObj.params['task'] = task.TaskDescription ? task.TaskDescription : '-';

    sendInBlueObj.params['user'] = parent.AllStaffsTypes.filter(
      item => item['value'] == task['UserAssignedID']
    )[0]['label'];

    const managerObj = parent.AllStaffsTypes.filter(item => item['value'] == task['ManagerID'])[0];
    if (email) {
      //These values will change based on type of email sent
      if (email instanceof Array) {
        sendInBlueObj.to = [];
        email.forEach((e, i) => {
          const name = parent.senderObj.toEmail
            ? parent.senderObj.toEmail
            : parent.AllStaffsTypes.filter(item => item['Email'] == e)[0]['label'];
          const obj = { email: e, name };
          if (e) sendInBlueObj.to.push(obj);
        });
      } else {
        if (email) {
          const name = parent.senderObj.toEmail
            ? parent.senderObj.toEmail
            : parent.AllStaffsTypes.filter(item => item['Email'] == email)[0]['label'];
          sendInBlueObj.to.push({ email: email, name: name });
        }
      }
    }

    sendInBlueObj.templateId = templeteId;

    // user can change based on the Header or the TaskDetail row items

    // manager is static from dropdown
    sendInBlueObj.params['manager'] = managerObj ? managerObj['label'] : '';

    sendInBlueObj.params['clientname'] = task['ClientName'] ? task['ClientName'] : '';

    sendInBlueObj.params['projectname'] = task.TemplateName ? task.TemplateName : '';
    sendInBlueObj.params['duedate'] = task.DueDate
      ? moment(task.DueDate).format('MM-DD-YYYY')
      : '-';
    sendInBlueObj.params['memo'] = task.ProjectMemo ? task.ProjectMemo : '-';
    sendInBlueObj.params['COMPANYNAME'] = parent.encrDecSrvc.getObject(AppConstants.companyName);

    if (sendInBlueObj?.to.length > 0) {
      parent.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(
        data => {
          parent.mangoAPISrvc.notify(
            'success',
            parent.translate.instant('Success'),
            AppConstants.emailSentMsg
          );
        },
        err => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  applyDueDates(currentRow) {
    const parent = this;
    const objList = [];
    parent.mangoAPISrvc
      .fetchprojectDetailsByHeaderId(currentRow.ProjectHeaderID)
      .subscribe((responseData: any) => {
        const taskList = responseData.taskList;
        const detailsList = taskList;
        for (let index = 0; index < detailsList.length; index++) {
          const element = detailsList[index];
          if (
            element['ProjectDetailsID'] == currentRow['ProjectDetailsID'] &&
            detailsList.length > index
          ) {
            const nextItem = detailsList.filter(
              itemData => itemData['TaskRow'] == currentRow['TaskRow'] + 1
            );

            if (nextItem && nextItem.length > 0 && !nextItem[0]['DueDate']) {
              nextItem[0]['DueDate'] = moment(currentRow['CompletionDate'])
                .add(1, 'days')
                .utc()
                .toDate();
              objList.push(nextItem[0]);
            }
          }
        }
        parent.bulkProjectDetailTable(objList).subscribe(data => {
          parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
        });
      });
  }

  fetchDdmSettings() {
    const parent = this;

    parent.companyTags = parent.encrDecSrvc.getObject(AppConstants.companyTags);

    if (!parent.companyTags) {
      // parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
        (res: any) => {
          if (res.length > 0) parent.companyTags = res[0];
          else {
            parent.companyTags = {
              CompanyID: '',
              IsEmailProjectAssigned: false,
              IsEmailTaskAssigned: false,
              IsEmailTaskReady: false,
              IsManagerProjectInProgress: false,
              IsManagerReadyReview: false,
              IsProjectRejected: false,
              Tag1: 'Available for Work',
              Tag2: 'Extended',
              Tag3: 'Urgent',
              Tag4: 'Missing Information',
              Tag5: 'Waiting on Client',
              Tag6: 'Notice',
              Tag7: 'Telephone Call',
              Tag8: 'Tag 8',
              Tag9: 'Tag 9 ',
              ddmSettingsID: null,
              DefaultUserID: null,
              DefaultManagerID: null
            };
          }

          parent.encrDecSrvc.addObject(AppConstants.companyTags, parent.companyTags);
          // parent.mangoAPISrvc.showLoader(false);
        },
        error => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
    parent.ddmSettingsObj = parent.companyTags;

    if (parent.ddmSettingsObj && Object.keys(parent.ddmSettingsObj).length > 0) {
      const allTags = parent.ddmSettingsObj.TagArray2;
      allTags?.map((item, i) => {
        if (item[0] != '') {
          const obj = {};
          obj['label'] = item[0];
          obj['value'] = (i + 1).toString();
          obj['color'] = item[1];
          obj['textColor'] =
            item[1] != ''
              ? parent.mangoUtils.setTextColor(parent.mangoUtils.hexToRgb(item[1]))
              : parent.mangoUtils.setTextColor(parent.mangoUtils.hexToRgb('#ffffff'));
          parent.tagsList.push(obj);
          parent.tagsListForFilters.push(obj);
        }
      });
    }
  }

  batchCreateAllTasks(dataList, rowObj) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    const repeat = rowObj['Repeat'] ? rowObj['Repeat'] : null;
    dataList.forEach((selectedItem, key) => {
      if (selectedItem['IsRepeatTask']) {
        selectedItem['DueDate'] = selectedItem['taskduedate']
          ? parent.calculateTaskRepeatData(selectedItem['taskduedate'], repeat)
          : parent.calculateTaskRepeatData(moment().format('MM/DD/YYYY HH:mm:ss'), repeat);
      } else {
        selectedItem['DueDate'] = null;
      }

      if (selectedItem?.TaskRow !== 1) selectedItem['IsTaskReady'] = false;
      else selectedItem['IsTaskReady'] = true;

      selectedItem['IsRepeatTask'] = false;
      selectedItem['IsCompleted'] = false;
      selectedItem['CompletionDate'] = null;
      selectedItem['CompanyID'] = rowObj.CompanyID;
      selectedItem['ClientID'] = rowObj.ClientID;
      selectedItem['ProjectHeaderID'] = rowObj.ProjectHeaderID;
      // selectedItem["DueDate"] = null;
      selectedItem['IsTaskAssignedSent'] = false;
      delete selectedItem.ProjectDetailsID;
      observableBatch.push(
        parent.http.post(`${environment.API_URL}/ddm/projectDetails/create`, selectedItem, {
          headers: headers
        })
      );
    });
    return forkJoin(observableBatch);
  }

  calculateTaskRepeatData(dueDate, repeat) {
    let newDate = null;
    dueDate = moment(dueDate).format('DD-MM-YYYY HH:mm:ss');
    if (repeat != 'none') {
      if (repeat == 'year') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'years').utc().toDate();
      } else if (repeat == 'quarterly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(3, 'months').utc().toDate();
      } else if (repeat == 'monthly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'months').utc().toDate();
      } else if (repeat == 'onetime') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'days').utc().toDate();
      } else if (repeat == 'week') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(7, 'days').toDate();
      } else if (repeat == 'semi-weekly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(14, 'days').toDate();
      } else if (repeat == 'semi-monthly') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(15, 'days').utc().toDate();
      } else if (repeat == 'daily') {
        newDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'days').toDate();
      }
    }

    return newDate;
  }

  calculateNewRepeatData(itemData) {
    let newDate = null;
    itemData['DueDate'] = itemData.NextDueDate;
    itemData['ActualDueDate'] = itemData['DueDate'];
    if (itemData.Repeat != 'none') {
      const obj = moment(itemData['DueDate']).format('MM/DD/YYYY HH:mm:ss');
      if (itemData.Repeat == 'year') {
        newDate = moment(obj).add(1, 'years').utc().toDate();
      } else if (itemData.Repeat == 'quarterly') {
        newDate = moment(obj).add(3, 'months').utc().toDate();
      } else if (itemData.Repeat == 'monthly') {
        newDate = moment(obj).add(1, 'months').utc().toDate();
      } else if (itemData.Repeat == 'onetime') {
        newDate = moment(obj).add(1, 'days').utc().toDate();
      } else if (itemData.Repeat == 'week') {
        newDate = moment(obj).add(7, 'days').toDate();
      } else if (itemData.Repeat == 'daily') {
        newDate = moment(obj).add(1, 'days').toDate();
      } else if (itemData.Repeat == 'semi-weekly') {
        newDate = moment(itemData['DueDate']).add(14, 'days').toDate();
      } else if (itemData.Repeat == 'semi-monthly') {
        newDate = moment(itemData['DueDate']).add(15, 'days').utc().toDate();
      }
    }
    itemData['NextDueDate'] = newDate;
    itemData['CompletionDate'] = null;
    itemData['ExtensionDate'] = null;
    itemData['DateReceived'] = null;
    itemData['isCompleted'] = false;
    itemData['Status'] = 'Pending';
    itemData['Tags'] = null;
    itemData['isNewCreated'] = false;
  }

  calculateRepeatData(item) {
    const parent = this;
    let newDate = null;
    delete item.dueDate;
    item['DueDate'] = item.NextDueDate;
    item['ActualDueDate'] = item['DueDate'];
    if (item.Repeat != 'none' && item.Repeat != null && item.Repeat != '') {
      if (item.Repeat == 'year') {
        newDate = moment(item['DueDate']).add(1, 'years').utc().toDate();
      } else if (item.Repeat == 'quarterly') {
        newDate = moment(item['DueDate']).add(3, 'months').utc().toDate();
      } else if (item.Repeat == 'monthly') {
        newDate = moment(item['DueDate']).add(1, 'months').utc().toDate();
      } else if (item.Repeat == 'onetime') {
        newDate = moment(item['DueDate']).add(1, 'days').utc().toDate();
      } else if (item.Repeat == 'week') {
        newDate = moment(item['DueDate']).add(7, 'days').toDate();
      } else if (item.Repeat == 'daily') {
        newDate = moment(item['DueDate']).add(1, 'days').toDate();
      } else if (item.Repeat == 'semi-weekly') {
        newDate = moment(item['DueDate']).add(14, 'days').toDate();
      } else if (item.Repeat == 'semi-monthly') {
        newDate = moment(item['DueDate']).add(15, 'days').utc().toDate();
      }
    }
    item['NextDueDate'] = newDate;
    item['CompletionDate'] = null;
    item['ExtensionDate'] = null;
    item['DateReceived'] = null;
    item['isCompleted'] = false;
    item['Status'] = 'Pending';
    item['Tags'] = null;
  }

  bulkProjectHeaderTable(list) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    list.forEach((selectedItem, index) => {
      observableBatch.push(
        this.http.put(
          `${environment.API_URL}/ddm/projectHeader/update/${selectedItem.ProjectHeaderID}`,
          selectedItem,
          { headers: headers }
        )
      );
    });
    return forkJoin(observableBatch);
  }

  bulkProjectHeaderUpdate(obj) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));

    for (let index = 0; index < Object.keys(obj).length; index++) {
      const element = Object.keys(obj)[index];
      const updateObj = {};
      updateObj['TotalTasks'] = obj[element];
      observableBatch.push(
        this.http.put(`${environment.API_URL}/ddm/projectHeader/update/${element}`, updateObj, {
          headers: headers
        })
      );
    }
    return forkJoin(observableBatch);
  }

  bulkTaskDelete() {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    parent.selectedTasksItems.forEach((selectedItem, index) => {
      observableBatch.push(
        this.http.delete(
          `${environment.API_URL}/ddm/projectDetails/delete/${selectedItem.ProjectDetailsID}`,
          { headers: headers }
        )
      );
    });
    return forkJoin(observableBatch);
  }

  getProjectNames(cId) {
    // this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getProjectNames(cId).subscribe(
      (data: any) => {
        for (let i = 0; i < data.length; ++i) {
          const obj = {};
          obj['label'] = data[i]['TemplateName'];
          obj['value'] = data[i]['TemplateName'];
          this.projectsList.push(obj);
        }
        // this.mangoAPISrvc.showLoader(false);
      },
      error => this.mangoAPISrvc.showLoader(false)
    );
  }

  getAllStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    this.AllStaffsTypes.unshift({ label: 'Unassigned', value: -1, StaffID: null, Email: null });
    this.staffListBatchUpdate.unshift({
      label: 'Unassigned',
      value: -1,
      StaffID: null,
      Email: null
    });
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive) continue;
      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      obj['StaffNumber'] = item[i]['StaffNumber'];
      obj['Email'] = item[i]['Email'];
      this.AllStaffsTypes.push(obj);
      this.staffListBatchUpdate.push(obj);
    }
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList || [];
    if (this.clientListDatasource.length == 0 || this.clientListDatasource.length !== list.length) {
      this.clientListDatasource = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientListDatasource.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientListDatasource.length; i++) {
        const client = this.clientListDatasource[i];
        if (
          client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] == false
        ) {
          filtered.push(client);
        } else if (
          client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] != true
        ) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  handleSelectClick(event) {
    this.selTaskClient = event;
  }

  handleColumns(event) {
    const selectedColumn = event.value;
    Object.keys(this.columnMappings).forEach(field => {
      const property = this.columnMappings[field];
      this[property] = selectedColumn.some(col => col.field === field);
    });
    this.saveSelectedCols(selectedColumn);
  }


  onChangeFilters(event, type: string) {
    switch (type) {
      case 'templateName':
        this.updatePMFilters(event.value, 0);
        break;
      case 'dueDate':
        this.updatePMFilters(event.value, 1);
        break;
      case 'status':
        if (this.taskObj['statusIDs'] && this.taskObj['statusIDs'].length > 0) {
          this.taskObj['statusIDs'] = this.taskObj['statusIDs'].map(item =>
            item['value'] ? item['value'] : item
          );
        }
        this.updatePMFilters(this.taskObj['statusIDs'], 2);
        break;
      case 'assigned':
        this.updatePMFilters(event.value, 3);
        break;
      case 'clientGroup':
        this.updatePMFilters(event.value, 4);
        break;
      case 'dateFrom':
        this.updatePMFilters(moment.utc(this.DateFrom).format('YYYY-MM-DD'), 5);
        break;
      case 'dateTo':
        this.updatePMFilters(moment.utc(this.DateTo).format('YYYY-MM-DD'), 6);
        break;
      default:
        break;
    }
  }

  isParamsEmpty(): boolean {
    const obj = this.projectParamsObj;

    return !(
      obj.TemplateName ||
      obj.dueDateID ||
      obj.statusID ||
      obj.assignedUserId ||
      obj.statusTask ||
      obj.dateRange
    );
  }

  initializeFilterValues() {
    if (!this.isParamsEmpty()) {
      this.doWhenTaskListLoaded(() => {
        this.getTaskList(true);
      });
    }

    if (
      this.projectParamsObj.redirectStatus === true &&
      this.projectParamsObj.filterStatus === false
    ) {
      this.taskObj.TemplateName = null;
      this.taskObj.assignedUserId = null;
      this.taskObj.statusID = null;
      this.taskObj.statusIDs = null;
      this.taskObj.dueDateID = null;
      this.taskObj.clientGroup = null;

      this.sharedService.triggerFilterProjectTaskListDialog({
        filterStatus: false,
        filterState: 'disabled'
      });

      this.userSelectedFilters = [
        this.taskObj.TemplateName,
        this.taskObj.dueDateID,
        this.taskObj.statusID,
        this.taskObj.assignedUserId,
        this.taskObj.clientGroup,

        moment(this.DateFrom).format('YYYY-MM-DD'),
        moment(this.DateTo).format('YYYY-MM-DD')
      ];

      this.doWhenTaskListLoaded(() => {
        this.getTaskList(true);
      });

      return;
    } else {
      this.sharedService.triggerFilterProjectTaskListDialog({
        filterState: 'enabled'
      });
    }

    this.mangoAPISrvc
      .getUsrSelFiltersByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe((data: any) => {
        if (
          Array.from(data.PMTaskFilters || [])
            ?.reverse()
            ?.slice(2)
            ?.filter(Boolean)?.length > 0
        ) {
          const filters = data.PMTaskFilters;

          this.taskObj.TemplateName = filters[0] ? filters[0] : null;
          this.taskObj.dueDateID = filters[1] ? filters[1] : null;
          this.taskObj.statusIDs = filters[2]
            ? filters[2].replaceAll(/[\[\]']+/g, '').split('-')
            : null;
          this.taskObj.assignedUserId = filters[3] ? parseInt(filters[3]) : null;
          this.taskObj.clientGroup = filters[4] ? parseInt(filters[4]) : null;

          this.DateFrom = filters[5] ? new Date(filters[5] + 'T00:00:00') : new Date();
          this.DateTo = filters[6] ? new Date(filters[6] + 'T00:00:00') : new Date();

          this.sharedService.triggerFilterProjectTaskListDialog({ filterStatus: true });
        } else {
          this.taskObj.TemplateName = null;
          this.taskObj.assignedUserId = null;
          this.taskObj.statusID = null;
          this.taskObj.statusIDs = null;
          this.taskObj.dueDateID = null;
          this.taskObj.clientGroup = null;

          this.sharedService.triggerFilterProjectTaskListDialog({ filterStatus: false });
        }

        this.userSelectedFilters = [
          this.taskObj.TemplateName,
          this.taskObj.dueDateID,
          this.taskObj.statusID,
          this.taskObj.assignedUserId,
          this.taskObj.clientGroup,
          moment(this.DateFrom).format('YYYY-MM-DD'),
          moment(this.DateTo).format('YYYY-MM-DD')
        ];

        if (this.isParamsEmpty()) {
          this.doWhenTaskListLoaded(() => {
            this.getTaskList(true);
          });
        }
      });
  }

  savePMFilters() {
    const arrToSave = this.userSelectedFilters.map(filter => (filter ? filter : 'null'));

    const objToSave = {
      PMTaskFilters: `{${arrToSave}}`
    };

    this.mangoAPISrvc
      .updateUsrSelFiltersByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        error => {}
      );
  }

  updatePMFilters(value, index) {
    if ([2].includes(index)) {
      if (value && value.length > 0) value = `[${value.join('-')}]`;
      else value = null;
    }
    this.userSelectedFilters[index] = value;
    this.savePMFilters();
  }

  fetchData() {
    this.isFilter = true;

    this.doWhenTaskListLoaded(() => {
      this.getTaskList(true, null, true);
    });

    this.sharedService.triggerFilterProjectTaskListDialog({ filterStatus: true });

    this.onCloseFilter();
  }

  onCloseFilter(): void {
    this.sharedService.triggerFilterProjectTaskListDialog({ dialogStatus: false });
  }

  onResetFilters() {
    this.selTaskClient = {
      ClientID: null,
      ClientName: 'All'
    };

    this.taskObj.TemplateName = null;
    this.taskObj.dueDateID = null;
    this.taskObj.statusID = null;
    this.taskObj.staffId = null;
    this.taskObj.assignedUserId = null;
    this.taskObj.clientGroup = null;

    this.DateFrom = new Date();
    this.DateTo = new Date();

    this.userSelectedFilters = [
      this.taskObj.TemplateName,
      this.taskObj.dueDateID,
      this.taskObj.statusID,
      this.taskObj.staffId,
      this.taskObj.assignedUserId,
      this.taskObj.clientGroup,
      this.DateFrom,
      this.DateTo
    ];

    this.savePMFilters();

    this.sharedService.triggerFilterProjectTaskListDialog({ filterStatus: false });

    this.doWhenTaskListLoaded(() => {
      this.getTaskList();
    });

    this.sharedService.triggerFilterProjectTaskListDialog({ dialogStatus: false });
  }

  async getAllTaskListReport() {
    const responseData: any = await this.mangoAPISrvc.asyncGetTasksList(
      this.projectParamsObj.dueDateID,
      this.projectParamsObj.assignedUserId,
      this.selTaskClient.ClientID,
      this.projectParamsObj.TemplateName,
      this.companyId,
      this.projectParamsObj.statusID,
      this.projectParamsObj.clientGroup,
      this.projectParamsObj.statusTask,
      this.projectParamsObj.dateRange,
      this.projectParamsObj.statusIDs,
      null
    );
    const taskList = responseData.taskList;
    taskList.map(task => {
      this.getStaffNames(task['UserAssignedIDArray'], task);
      this.getManagerName(task['ManagerID'], task);
      task['ExtensionDate'] = moment.utc(task['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      task['compareTaskDueDate'] = task['taskduedate'];
      task['IsTaskAssignedSent'] = task['IsTaskAssignedSent'];
      task['compareExtensionDate'] = task['ExtensionDate'];
      task['isRadioDisabled'] = true;
      task.SortableDueDate = task.DueDate;
      switch (task.Status) {
        case 'Pending':
          task.statusClass = 'pending';
          break;

        case 'In Progress':
          task.statusClass = 'inprogress';
          break;

        case 'Ready for Review':
          task.statusClass = 'review';
          break;

        case 'Completed':
          task.statusClass = 'completed';
          break;
      }

      if (task['GroupDescriptionIDArray'] && task['GroupDescriptionIDArray'].length > 0) {
        task['GroupDescriptionValues'] = this.groupList
          ?.filter(group => task['GroupDescriptionIDArray'].includes(group['value']))
          .map(group => group['label'])
          .join(', ');
      }
      this.allProjectTaskList = taskList;
    });
  }

  getTaskList(isLoading?: any, filterType?, isFiltered?: boolean) {
    try {
      if (this.taskListLoadingStatus === true) {
        return;
      }

      this.taskListLoadingStatus = true;
      this.taskListLoadedStatus = false;

      let argsObj = {
        TemplateName: null,
        dueDateID: null,
        statusID: null,
        statusIDs: null,
        staffId: null,
        assignedUserId: null,
        clientGroup: null,
        statusTask: null,
        dateRange: null
      };

      if (!this.isParamsEmpty() && !isFiltered) {
        argsObj = {
          ...this.projectParamsObj
        };
      } else {
        argsObj = {
          ...this.taskObj
        };

        if (argsObj.dueDateID == 'custom') {
          argsObj.dueDateID = [
            `${moment(this.DateFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss')}`,
            `${moment(this.DateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`
          ].join('|');
        }
      }

      if (filterType) {
        const temp = this._selectedColumns.map(col => col.field);

        switch (filterType) {
          case 'compareTaskDueDate':
            temp?.push('compareTaskDueDate');
            break;

          case 'Status':
            temp?.push('Status');
            break;

          case 'StaffNames':
            temp?.push('StaffNames');
            break;
        }

        this._selectedColumns = this.cols.filter(col => temp.includes(col.field));

        // this.onChangeSelectedCols({value: this._selectedColumns});
      }

      this.isApiloaded = false;

      this.selTaskClient = this.selTaskClient
        ? this.selTaskClient
        : (this.selTaskClient = { ClientID: null, ClientName: 'All' });

      argsObj['statusIDs'] = argsObj['statusIDs'] ? `[${argsObj['statusIDs'].join('-')}]` : null;

      this.mangoAPISrvc.showLoader(true);

      //argsObj.statusTask = argsObj.statusTask != 'Ready' ?  argsObj.statusTask : null;  //display all
      //console.log('*********************');
      //console.log(argsObj);
      this.mangoAPISrvc
        .getTasksList(
          argsObj.dueDateID,
          argsObj.assignedUserId,
          this.selTaskClient.ClientID,
          argsObj.TemplateName,
          this.companyId,
          argsObj.statusID,
          argsObj.clientGroup,
          argsObj.statusTask,
          argsObj.dateRange,
          argsObj.statusIDs,

          {
            pageIndex: this.taskListPageIndex,

            nextPage: this.taskListNextPage,

            pageSize: this.taskListPageSize,

            _slicePaginationStatus: false,

            _sqlPaginationStatus: true,

            searchQuery: this.taskListSearchQuery,

            sortQuery: this.taskListSortQuery,

            taskTypeViewQuery: this.projectParamsObj.statusTask ? undefined : this.taskTypeViewQuery
          }
        )
        .subscribe(
          (responseData: any) => {
            const taskList = responseData.taskList;

            taskList.forEach(task => {
              const staffMember = this.AllManagers.find(
                staff => staff.StaffID === task.UserAssignedID
              );
              if (staffMember) {
                task.staffLabel = staffMember.label;
              }
            });

            const totalCount = responseData.totalCount;

            this.projectTaskListTotalCount = totalCount;
            if (this.taskListSearchQuery && this.taskListSearchQuery.length > 0) {
              this.filteredTasksItemsSize = this.projectTaskListTotalCount;
            }

            if (taskList.length <= 0) {
              this.resetPagination();
            }

            taskList.map(item => {
              this.getStaffNames(item['UserAssignedIDArray'], item);
              this.getManagerName(item['ManagerID'], item);
              item['ExtensionDate'] = item['ExtensionDate']
                ? moment.utc(item['ExtensionDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
                : null;

              item['compareTaskDueDate'] = item['taskduedate'] ? item['taskduedate'] : null;

              item['compareDueDate'] = item['DueDate'] ? item['DueDate'] : null;

              item['IsTaskAssignedSent'] = item['IsTaskAssignedSent']
                ? item['IsTaskAssignedSent']
                : false;

              item['compareExtensionDate'] = item['ExtensionDate'] ? item['ExtensionDate'] : null;

              item['isRadioDisabled'] = true;

              /*;
                  item.username = item.username? item.username : "Unassigned";
                  */

              item.SortableDueDate = item.ExtensionDate || item.DueDate;

              switch (item.Status) {
                case 'Pending':
                  item.statusClass = 'pending';

                  break;

                case 'In Progress':
                  item.statusClass = 'inprogress';

                  break;

                case 'Ready for Review':
                  item.statusClass = 'review';

                  break;

                case 'Completed':
                  item.statusClass = 'completed';

                  break;
              }

              if (item['GroupDescriptionIDArray'] && item['GroupDescriptionIDArray'].length > 0) {
                item['GroupDescriptionValues'] = this.groupList
                  ?.filter(group => item['GroupDescriptionIDArray'].includes(group['value']))
                  .map(group => group['label'])
                  .join(', ');
              }
            });

            /*;
            Removed unnecessary code with regards to isTaskReady
            */

            this.isApiloaded = taskList.length == 0 ? true : false;

            this.unfilteredTasksDataSource = taskList;

            this.projectTaskList = taskList;

            if (isLoading && isLoading === true) {
              if (this.fromCompleteTask) {
                this.fromCompleteTask = false;
                this.taskListLoadingStatus = false;
                this.taskListLoadedStatus = true;

                this.mangoAPISrvc.showLoader(false);
              } else {
                setTimeout(() => {
                  this.filterDataTable(() => {
                    this.taskListLoadingStatus = false;
                    this.taskListLoadedStatus = true;

                    this.mangoAPISrvc.showLoader(false);
                  });
                }, 100);
              }
            } else {
              this.taskListLoadingStatus = false;
              this.taskListLoadedStatus = true;

              this.mangoAPISrvc.showLoader(false);
            }
          },
          error => {
            console.error(['cannot request get task list;', error]);

            this.taskListLoadingStatus = false;
            this.taskListLoadedStatus = false;

            this.mangoAPISrvc.showLoader(false);
          }
        );
    } catch (error) {
      console.error(['cannot get task list;', error]);

      this.taskListLoadingStatus = false;
      this.taskListLoadedStatus = false;

      this.mangoAPISrvc.showLoader(false);
    }
  }

  filterDataTable(callback?) {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || '';

      this.dt.reset();

      if (this.searchTextStr) {
        const event = new Event('input', {
          bubbles: true,
          cancelable: true
        });

        this.searchValue.nativeElement.dispatchEvent(event);

        /*;
              @note:
                This is calling the get task list twice
                because we've already dispatched the event
                then you select the value again.
              @note;
            */
        //this.searchValue.nativeElement.select( );
      } else {
        this.searchValue.nativeElement.focus();
      }

      this.filteredTasksItemsSize = -1;

      if (typeof callback == 'function') {
        callback();
      }
    }, 50);
  }

  onSearchInput($event) {
    if (typeof this.searchInputTimeout != 'undefined') {
      clearTimeout(this.searchInputTimeout);

      this.searchInputTimeout = undefined;
    }

    this.searchInputTimeout = setTimeout(() => {
      const searchQuery = $event.target.value || '';

      this.taskListSearchQuery = searchQuery;

      this.searchTextStr = searchQuery;
      if (searchQuery.length <= 0) {
        this.clearSearchFilter();
      } else {
        this.doWhenTaskListLoaded(() => {
          this.getTaskList();
        });
      }

      if (typeof this.searchInputTimeout != 'undefined') {
        clearTimeout(this.searchInputTimeout);

        this.searchInputTimeout = undefined;
      }
    }, 1000);
  }

  onSort($event) {
    if (this.taskListPageIndex > 1) this.resetPagination();

    this.sortField = $event.multisortmeta;

    this.encrDecSrvc.addObject('projListTasks_sortField', this.sortField);
    if (this.sortField.length > 0) {
      const sortQuery = this.sortField
        .reduce(
          (sortQueryList, sortData) => {
            const order = sortData.order > 0 ? '+' : '-';

            const field = this.taskListSortColumnMap[sortData.field];

            sortQueryList.push(`${order}${field}`);

            return sortQueryList;
          },

          []
        )
        .join(',');

      if (sortQuery === this.taskListSortQuery) {
        return;
      }

      this.taskListSortQuery = sortQuery;
    } else {
      this.taskListSortQuery = undefined;

      this.resetPagination();
    }

    this.doWhenTaskListLoaded(() => {
      this.getTaskList();
    });
  }

  onTablePageChange($event?) {
    this.dt.reset();
    this.taskListPageIndex = $event.page + 1;

    this.taskListNextPage = $event.first;

    this.taskListPageSize = $event.rows;

    if (this.selectedRowsPerPage !== $event.rows) {
      this.mangoAPISrvc
        .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), {
          TaskListDefaultPagination: $event.rows
        })
        .subscribe(
          (data: any) => {},
          error => {}
        );
    }
    if (this.taskListSearchQuery?.length > 0) {
      this.filterDataTable();
    }
    {
      this.doWhenTaskListLoaded(() => {
        this.getTaskList();
      });
    }
  }

  clearSearchFilter() {
    this.taskListSearchQuery = '';
    this.searchTextStr = '';
    this.searchValue.nativeElement.value = '';

    this.filteredData = [];
    this.filteredTasksItemsSize = -1;

    this.encrDecSrvc.removeObject('projListTasks_' + AppConstants.SearchString);

    this.resetPagination();

    this.doWhenTaskListLoaded(() => {
      this.getTaskList();
    });
  }

  onFilter(obj) {
    this.filteredTasksItemsSize = obj.filteredValue.length;

    this.filteredData = obj.filteredValue;

    this.encrDecSrvc.addObject(
      'projListTasks_' + AppConstants.SearchString,
      obj.filters.global?.value
    );
  }

  resetPagination() {
    this.taskListPageIndex = 1;
    this.taskListNextPage = 0;
    this.taskListPageSize = this.selectedRowsPerPage;
    this.dt?.reset();
    setTimeout(() => this.paginator?.changePage(0));
  }

  doWhenTaskListLoaded(callback?) {
    if (this.taskListLoadingStatus === true) {
      if (typeof this.doWhenTaskListLoadedTimeout != 'undefined') {
        clearTimeout(this.doWhenTaskListLoadedTimeout);
        this.doWhenTaskListLoadedTimeout = undefined;
      }

      this.doWhenTaskListLoadedTimeout = setTimeout(() => {
        this.doWhenTaskListLoaded(callback);
      }, 1000);
    } else if (this.taskListLoadedStatus === true || this.taskListLoadingStatus === false) {
      if (typeof callback == 'function') {
        callback();
      }

      if (typeof this.doWhenTaskListLoadedTimeout != 'undefined') {
        clearTimeout(this.doWhenTaskListLoadedTimeout);
        this.doWhenTaskListLoadedTimeout = undefined;
      }
    } else {
      if (typeof this.doWhenTaskListLoadedTimeout != 'undefined') {
        clearTimeout(this.doWhenTaskListLoadedTimeout);
        this.doWhenTaskListLoadedTimeout = undefined;
      }
    }
  }

  onDisregardIsCompleteInOrder(type) {
    if (type === 'open') {
      this.isDisregardIsCompleteInOrder = true;
    } else if (type === 'ready') {
      this.isDisregardIsCompleteInOrder = false;
    }

    if ((this.projectParamsObj.statusTask && !this.isFilter) || this.isDisregardIsCompleteInOrder) {
      this.taskTypeViewQuery = 'open';
    } else {
      this.taskTypeViewQuery = 'ready';
    }

    this.doWhenTaskListLoaded(() => {
      this.getTaskList();
    });
  }

  getStaffNames(ids, data) {
    if (!ids || ids.length == 0) {
      data['StaffNames'] = 'Unassigned';
      return;
    }
    const allList = this.encrDecSrvc.getObject(AppConstants.allStaffList);

    const labels = allList
      .filter(item => ids.includes(item['StaffID']))
      .map(item => (item.Inactive ? item['StaffName'] + ' (Inactive User)' : item['StaffName']));
    data['StaffNames'] = labels.join(', ');
  }

  getManagerName(managerID, data) {
    if (!managerID || managerID < 0) {
      data['Manager'] = 'Unassigned';
    } else {
      const staffList = this.encrDecSrvc.getObject(AppConstants.allStaffList);

      if (staffList && Array.isArray(staffList)) {
        const manager = staffList.find(staff => staff.StaffID === managerID);

        // Check if manager exists before accessing StaffName
        if (manager) {
          data['Manager'] = manager.StaffName;
        } else {
          data['Manager'] = 'Manager not found';
        }
      } else {
        data['Manager'] = 'Staff list is unavailable';
      }
    }
  }

  getSortValues() {
    if (!this.sortField) return '';

    const _this = this;
    const getColumn = field => {
      if (this._selectedColumns?.length > 0) {
        return _this._selectedColumns.find(x => x.field == field)?.header ?? field;
      }
    };

    return `Sorted by ${_this.sortField
      ?.map(x => {
        return `${getColumn(x.field)} ${
          x.order == 1
            ? '<i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>'
            : '<i class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></i>'
        }`;
      })
      .join(', ')}`;
  }

  redirectToProjectDetails(item) {
    item.fromTasksList = true;
    this.encrDecSrvc.addObject(AppConstants.ddmProjectDetails, JSON.stringify(item));
    this.router.navigate([`/project-management/${item.ProjectHeaderID}/projectDetails`]);
  }

  getToolTip(obj) {
    if (obj.ExtensionDate) return 'Due Date : ' + moment(obj.DueDate).format('MM/DD/YYYY');

    return obj.NextDueDate ? 'Next Due Date : ' + moment(obj.NextDueDate).format('MM/DD/YYYY') : '';
  }

  async generateDataForExport(reportType: string) {
    /**
     * This function is to modify the data to display all the necessary details, which cannot just be access via key-value pair.
     */
    this.mangoAPISrvc.showLoader(true);
    reportType === 'all' &&
      this.allProjectTaskList.length === 0 &&
      (await this.getAllTaskListReport());
    let source;
    if (reportType === 'current') {
      source = this.filteredData?.length > 0 ? this.filteredData : this.projectTaskList;
    } else if (reportType === 'all') {
      source = this.allProjectTaskList;
    }
    const newSource = source.map(data => {
      const obj = JSON.parse(JSON.stringify(data));
      obj['DateReceived'] = obj.DateReceived ? moment(obj.DateReceived).format('YYYY-MM-DD') : '-';
      obj['compareTaskDueDate'] = obj.compareTaskDueDate
        ? moment(obj.compareTaskDueDate).format('YYYY-MM-DD')
        : '-';
      obj['SortableDueDate'] =
        (obj.compareExtensionDate && moment(obj.compareExtensionDate).format('YYYY-MM-DD')) ||
        (obj.compareDueDate && moment(obj.compareDueDate).format('YYYY-MM-DD')) ||
        '-';
      return obj;
    });
    const selectedColFields = this.selectedColumns.map(col => col.field);
    selectedColFields.unshift('TaskDescription');
    selectedColFields.unshift('TaskRow');
    selectedColFields.unshift('ClientName');
    const filteredArray = [];
    newSource.map(obj => {
      const filteredObj = {};
      selectedColFields.map(col => {
        if (Object.keys(obj).includes(col)) {
          filteredObj[col] = obj[col];
        }
      });
      filteredArray.push(filteredObj);
    });
    this.mangoAPISrvc.showLoader(false);
    return filteredArray;
  }

  async exportExcel() {
    const selectedColFields = this.selectedColumns.map(col => col.header);
    selectedColFields.unshift('Task Description');
    selectedColFields.unshift('Task Row');
    selectedColFields.unshift('Client Name');
    const body = await this.generateDataForExport('all');
    const worksheet = xlsx.utils.json_to_sheet(body);
    const range = xlsx.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.r; C <= range.e.r; ++C) {
      const address = xlsx.utils.encode_col(C) + '1'; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address].v = selectedColFields[C]; // Change column headers to selectedColFields's header value
    }
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Tasks');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  async exportPdf(reportType: string) {
    const doc: any = new jsPDF('l', 'pt');

    let columns = [
      { title: 'Client', dataKey: 'ClientName' },
      { title: 'Task #', dataKey: 'TaskRow' },
      { title: 'Task Description', dataKey: 'TaskDescription' },
      { title: 'Task Due Date', dataKey: 'compareTaskDueDate' },
      { title: 'Project Name', dataKey: 'TemplateName' },
      { title: 'In Date', dataKey: 'DateReceived' },
      { title: 'Project Due Date', dataKey: 'SortableDueDate' },
      { title: 'Client Groups', dataKey: 'GroupDescriptionValues' },
      { title: 'Status', dataKey: 'Status' },
      { title: 'Manager', dataKey: 'Manager' },
      { title: 'Assigned To', dataKey: 'StaffNames' }
    ];
    const body = await this.generateDataForExport(reportType);

    this.selectedColumns.unshift({
      field: 'TaskDescription',
      header: this.translate.instant('project'),
      rowClass: 'width-9p p-text-left'
    });
    this.selectedColumns.unshift({
      field: 'TaskRow',
      header: this.translate.instant('project'),
      rowClass: 'width-9p p-text-left'
    });
    this.selectedColumns.unshift({
      field: 'ClientName',
      header: this.translate.instant('Client'),
      rowClass: 'width-9p p-text-left'
    });

    columns = columns.filter(item =>
      this.selectedColumns.map(col => col.field).includes(item['dataKey'])
    );
    doc.autoTable(columns, body);
    doc.save(`Tasks_export_${new Date().getTime()}.pdf`);
    const notIncluded = ['ClientName', 'TaskDescription', 'TaskRow'];
    this.selectedColumns = this.selectedColumns.filter(
      item => !notIncluded.includes(item['field'])
    );
  }

  async exportCSVFile(reportType: string) {
    this.mangoAPISrvc.showLoader(true);
    const originalData = this.dt.value;

    reportType === 'all' &&
      this.allProjectTaskList.length === 0 &&
      (await this.getAllTaskListReport());
    this.dt.value = reportType === 'all' ? this.allProjectTaskList : originalData;

    this.selectedColumns.unshift({
      field: 'TaskDescription',
      header: this.translate.instant('task'),
      rowClass: 'width-9p p-text-left'
    });
    this.selectedColumns.unshift({
      field: 'TaskRow',
      header: this.translate.instant('Task #'),
      rowClass: 'width-9p p-text-left'
    });
    this.selectedColumns.unshift({
      field: 'ClientName',
      header: this.translate.instant('Client'),
      rowClass: 'width-9p p-text-left'
    });
    this.updateColumns();
    this.dt.exportCSV();
    const notIncluded = ['ClientName', 'TaskDescription', 'TaskRow'];
    this.selectedColumns = this.selectedColumns.filter(
      item => !notIncluded.includes(item['field'])
    );
    this.dt.value = originalData;
    this.backupdateColumns();
    this.mangoAPISrvc.showLoader(false);
  }

  private backupdateColumns() {
    this.selectedColumns = this.copySelectedColunms;
  }
  private updateColumns() {
    this.copySelectedColunms = this.selectedColumns;
    this.selectedColumns.forEach(col => {
      if (col.field == 'staffLabel') {
        col.field = 'Manager';
      }
    });
  }

  saveSelectedCols(list: Array<any>): void {
    let columnsToSave = '';
    list.map((col, index) => {
      if (index > 0) columnsToSave += `, ${col.field}`;
      else columnsToSave += col.field;
    });
  
    const objToSave = { TaskListCols: `{${columnsToSave}}` };
  
    this.mangoAPISrvc.updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        (data: any) => {
          this.TaskListCols = data.TaskListCols;
        },
        err => {}
      );
  }

  onColReorder(event) {
    this.saveSelectedCols(event.columns);
  }

  ngOnDestroy(): void {
    this.sharedService.triggerFilterProjectTaskListDialog({ resetStatus: true });

    this.filterProjectTaskListDialog.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.searchValue && this.searchValue.nativeElement) {
      this.searchValue.nativeElement.focus();
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.adjustScrollHeight();
  }
  
  private adjustScrollHeight(): void {
    const screenHeight = window.innerHeight;
    if (screenHeight < 900) {
      this.scrollHeight = '62vh';
    } else {
      this.scrollHeight = '69vh';
    }
  }
}