<div class="batch-time-entry">
    <div>
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple type="button" label="{{'Save-All' | translate}}" icon="fal fa-check" class="p-button-sm p-pr-2 p-mr-2"
                [disabled]="isValidForm" (click)="saveAll()"></button>
            <button pButton pRipple type="button" label="{{'Clear-All' | translate}}" icon="fal fa-times" class="p-button-sm p-pr-2 p-mr-2"
                (click)="clearALL()"></button>
        </span>
    </div>
    <div class="layout-content p-pt-0">
        <div class="p-mx-1">
            <div class="p-col-12">
                <div class="card p-pt-2">
                    <p-table #dt [value]="timeSheetList" [rowHover]="true" [responsive]="true" [rows]="10"
                        [rowHover]="true" styleClass="p-datatable-sm p-datatable-responsive-demo"
                        [totalRecords]="timeSheetList.length" [sortOrder]="1" [resizableColumns]="true"
                        [rows]="timeSheetList.length" [paginator]="timeSheetList.length > 10" 
                        [rowsPerPageOptions]="[10,20,30]">

                        <!-- Start: Table Caption-->
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <p-dropdown [options]="staffList" [style]="{'width':'260px'}"
                                            [ngModelOptions]="{ standalone: true }" [(ngModel)]="staffID"
                                            (onChange)="selectStaff($event)" (click)="checkChangeUser();">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-col-6 p-text-right">
                                        <p-checkbox [(ngModel)]="showStartStopTime" binary="true"
                                            label="{{'Show-Time' | translate}}" (click)="updateStartStopTime()">
                                        </p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <!-- End: Table Caption-->

                        <!-- Start: Table Header-->
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-8p p-text-center">{{'Actions' | translate}}</th>
                                <th class="width-8p p-text-left" pSortableColumn="Ddate">
                                    {{'date' | translate}}
                                    <p-sortIcon field="Ddate"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left required-field-lg" pSortableColumn="TimeStart"
                                    [hidden]="!showStartStopTime">
                                    {{'Start' | translate}}
                                    <p-sortIcon field="TimeStart"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-left required-field-lg" pSortableColumn="TimeStop"
                                    [hidden]="!showStartStopTime">
                                    {{'Stop' | translate}}
                                    <p-sortIcon field="TimeStop"></p-sortIcon>
                                </th>
                                <th class="width-12p p-text-left required-field-lg" pSortableColumn="ClientName">
                                    {{'client.name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left required-field-lg" pSortableColumn="EngagementName">
                                    {{'timer.engagement' | translate}}
                                    <p-sortIcon field="EngagementName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left required-field-lg" pSortableColumn="Activity">
                                    {{'activity' | translate}}
                                    <p-sortIcon field="Activity"></p-sortIcon>
                                </th>

                                <th class="width-2p p-text-center" [ngClass]="{'required-field-lg': companyGlobalSetting.TimeMemoRequired,'': !companyGlobalSetting.TimeMemoRequired}" pTooltip="{{ 'work.description' | translate }}/ {{ 'Private-Memo' | translate }}">
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-5p p-text-right" [hidden]="!showStartStopTime" pTooltip="{{ 'actual_hours' | translate }}"
                                    tooltipPosition="top">
                                    {{'time.Actual' | translate}}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-8p p-text-right required-field-lg" pTooltip="{{ 'Billable-Hours' | translate }}"
                                    tooltipPosition="top">
                                    {{'billable' | translate}}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <!--  -->
                                <th class="width-8p p-text-right" [hidden]="isHideBillRateColumn">
                                    {{'time.Billing-Rate' | translate}}
                                </th>
                                <th class="width-8p p-text-right" [hidden]="isHideBillRateColumn">
                                    {{'billable-amount' | translate}}
                                </th>
                                <th class="width-8p p-text-right" [hidden]="isHideBillRateColumn">
                                    {{'Non-Billable-Amount' | translate}}
                                </th>
                                <th class="width-7p p-text-center">{{'billable' | translate}}</th>
                            </tr>
                        </ng-template>
                        <!-- End: Table Header-->

                        <!--Start: Table Body -->
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                            <tr>
                                <!-- Start: Actions Column -->
                                <td class="width-8p p-text-center width-7p">
                                    <i class="fal fa-plus p-mr-2 cursor-hand ic-blue ic-size-20"
                                        *ngIf="timeSheetList.length - 1 == i" (click)="addRow(i, rowData, true, true)"
                                        pTooltip="{{'time.Add-New-Line' | translate}}" tooltipPosition="right">
                                    </i>
                                    <i class="fal fa-trash-alt ic-red cursor-hand ic-size-18" pTooltip="{{'Delete-Row' | translate}}" tooltipPosition="right" (click)="deleteRow($event, i)"
                                        *ngIf="timeSheetList.length > 1">
                                    </i>
                                </td>
                                <!-- End: Actions Column -->

                                <!-- Start: Date Column -->
                                <td class="width-8p p-text-left" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="rowData['Ddate']" (ngModelChange)="unsavedChanges = true;" (onSelect)="verifySystemLocking($event)"
                                                yearRange="2000:2099" dateFormat="mm/dd/yy" showButtonBar="true">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{ rowData["Ddate"] | date: "MM/dd/yyyy" }}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- End: Date Column -->

                                <td class="width-7p p-text-left" [hidden]="!showStartStopTime" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar autocomplete="off" [timeOnly]="true" appendTo="body"
                                                [hourFormat]="12" [stepMinute]="15" [(ngModel)]="rowData['TimeStart']"
                                                name="timeStart"
                                                (onSelect)="calculateTimeDifference(rowData, false);editCellComplete($event);unsavedChanges = true"
                                                (onBlur)="editCellComplete($event);calculateTimeDifference(rowData, false)"
                                                (onInput)="calculateTimeDifference(rowData, false)">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{
                                                rowData["TimeStart"] | date: "shortTime"
                                                }}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td class="width-7p p-text-left" [hidden]="!showStartStopTime" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [timeOnly]="true" hourFormat="12"
                                                [ngModelOptions]="{ standalone: true }"
                                                [(ngModel)]="rowData['TimeStop']" appendTo="body" 
                                                (onSelect)="editCellComplete($event);calculateTimeDifference(rowData, false)" (onInput)="calculateTimeDifference(rowData, false)"
                                                (onBlur)="editCellComplete($event);calculateTimeDifference(rowData, false);unsavedChanges = true"
                                                [stepMinute]="15">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{ rowData["TimeStop"] | date: "shortTime" }}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="width-12p p-text-left p-text-truncate p-text-nowrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span *ngIf="!rowData.isNew">{{
                                                rowData.ClientName
                                                }}</span>
                                            <p-autoComplete 
                                                *ngIf="rowData.isNew" 
                                                autocomplete="off" 
                                                [suggestions]="filteredClients"
                                                (completeMethod)="filterClients($event)" 
                                                [minLength]="1" 
                                                [(ngModel)]="selClient"
                                                [dropdown]="true" 
                                                placeholder="{{ 'select_client' | translate }}" 
                                                [ngModelOptions]="{standalone: true}"
                                                (onSelect)="handleClientSelectClick($event.value, rowData); unsavedChanges = true" 
                                                appendTo="body" 
                                                (onFocus)="$event.target.select()" 
                                                class="text-ellipse"
                                                field="ClientName" 
                                                [lazy]="true" 
                                                [virtualScroll]="true" 
                                                panelAppendTo="body"
                                                [panelStyleClass]="'custom-autocomplete-panel-batch-entry'"
                                                [virtualScrollItemSize]="20"
                                            >
                                                <ng-template let-client pTemplate="item">
                                                    <div>{{ client.ClientName }}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                                                </ng-template>
                                            </p-autoComplete>                                                                                   
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div pTooltip="{{ rowData['ClientName'] }}"
                                                tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top"
                                                class="p-text-left p-text-truncate p-text-nowrap">{{
                                                rowData["ClientName"] }}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn class="width-10p p-text-left p-text-truncate p-text-nowrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span *ngIf="!rowData.isNew">{{
                                                rowData.EngagementName
                                                }}</span>
                                            <p-autoComplete *ngIf="rowData.isNew"
                                                [suggestions]="rowData['engagementsList']" [minLength]="1"
                                                [dropdown]="true" placeholder="{{ 'select-engagement' | translate }}"
                                                (completeMethod)="filterEnggSingle($event, rowData)" (onSelect)="handleEngagementSelectClick($event.value, rowData, false);unsavedChanges = true"
                                                appendTo="body" (onFocus)="$event.target.select()" class="text-ellipse" field="label"
                                                [disabled]="rowData.ClientID == null">
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div pTooltip="{{ rowData['EngagementName'] }}"
                                                tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top"
                                                class="p-text-left p-text-truncate p-text-nowrap">{{
                                                rowData["EngagementName"] }}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn [ngStyle]="{height: '36px'}"
                                    class="width-10p p-text-left p-text-truncate p-text-nowrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span *ngIf="!rowData.isNew">{{
                                                rowData.ServiceCode
                                                }}</span>
                                            <p-autoComplete *ngIf="rowData.isNew" [suggestions]="activityList"
                                                [minLength]="1" [dropdown]="true"
                                                placeholder="{{ 'select_activity' | translate }}"
                                                (completeMethod)="filterActiveSingle($event)" (onSelect)="handleActivitySelectClick($event.value, rowData, true);unsavedChanges = true" 
                                                appendTo="body" (onFocus)="$event.target.select()" class="text-ellipse" field="label" 
                                                [disabled]="rowData.ClientID == null || rowData.EngagementName == null">
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div pTooltip="{{ rowData['ServiceCode'] }}"
                                                tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top"
                                                class="p-text-nowrap p-text-truncate">{{ rowData["ServiceCode"] }}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td class="width-2p p-text-center">
                                    <i [ngClass]="{
                'fas fa-file-alt ic-blue': !!rowData.Memo,
                'fal fa-sticky-note': !rowData.Memo
              }" aria-hidden="true" (click)="addMemo(rowData)"></i>
                                </td>

                                <td class="width-5p p-text-right" showDelay="800" pTooltip="{{ actualTimeTooltip }}"
                                    tooltipStyleClass="tooltip-no-wrap" tooltipPosition="right"
                                    [hidden]="!showStartStopTime">
                                    {{ rowData["ElaspedTime"] | number: "1.2-2" }}
                                </td>

                                <td *ngIf="showStartStopTime" class="width-8p p-text-right">
                                    <span>{{ rowData["TotalTime"] | number: "1.2-2" }}</span>
                                </td>

                                <td *ngIf="!showStartStopTime" pEditableColumn class="width-8p p-text-right">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" style="width: 100px;"
                                                [(ngModel)]="rowData.TotalTime" [ngModelOptions]="{ standalone: true }"
                                                (ngModelChange)="changeMoney($event, rowData);unsavedChanges = true"
                                                (focus)="$event.target.select()"
                                                [disabled]="
                                                rowData.ClientID == null || rowData.EngagementName == null || rowData.ServiceCodeID == null"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{ rowData["TotalTime"] | number: "1.2-2" }}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td class="width-8p p-text-right" [hidden]="isHideBillRateColumn">
                                    {{ rowData["BillingRate"] | currency: "USD":"symbol":"1.2-2" }}
                                </td>

                                <td class="width-8p p-text-right" [hidden]="isHideBillRateColumn">
                                    <span *ngIf="rowData.Billable">{{
                                        rowData["StandardAmount"]
                                        }}</span>
                                    <span *ngIf="!rowData.Billable">$0.00</span>
                                </td>

                                <td class="width-8p p-text-right" [hidden]="isHideBillRateColumn">
                                    <span *ngIf="!rowData.Billable">{{
                                        rowData["StandardAmount"]
                                        }}</span>
                                    <span *ngIf="rowData.Billable">$0.00</span>
                                </td>

                                <td class="width-7p p-text-center" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-checkbox [(ngModel)]="rowData['Billable']" value="rowData['Billable']"
                                                [ngModelOptions]="{ standalone: true }" binary="true"
                                                (onChange)="footerTotals();unsavedChanges = true"
                                                (keydown.Tab)="addRow(i, rowData, true, true)"></p-checkbox>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-checkbox [(ngModel)]="rowData['Billable']" value="rowData['Billable']"
                                                [ngModelOptions]="{ standalone: true }" binary="true"
                                                (onChange)="footerTotals();unsavedChanges = true"
                                                (keydown.Tab)="addRow(i, rowData, true, true)"></p-checkbox>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                            </tr>
                        </ng-template>
                        <!--End: Table Body -->

                        <!--Start: Table Footer -->
                        <ng-template pTemplate="footer">
                            <tr *ngIf="totalBillableHrs > 0">
                                <td [hidden]="showStartStopTime" colspan="6" class="p-text-right ic-red">
                                    {{'Totals' | translate}}:
                                </td>
                                <td [hidden]="!showStartStopTime" colspan="8" class="p-text-right ic-red">
                                    {{'Totals' | translate}}:
                                </td>
                                <td class="p-text-right ic-red" [hidden]="!showStartStopTime">
                                    {{ totalElapseHrs | number: "1.2-2" }}
                                </td>
                                <td class="p-text-right ic-red">
                                    {{ totalBillableHrs | number: "1.2-2" }}
                                </td>
                                <td class="p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                    {{ totalBillingRates | currency: "USD":"symbol":"1.2-2" }}
                                </td>
                                <td class="p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                    {{ totalBillableAmt | currency: "USD":"symbol":"1.2-2" }}
                                </td>
                                <td class="p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                    {{ totalNonBillableAmt | currency: "USD":"symbol":"1.2-2" }}
                                </td>
                                <td colspan="1" class="p-text-right"></td>
                            </tr>
                        </ng-template>
                        <!--End: Table Footer -->

                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start: Dialog -->
<p-dialog header="Work Description" [(visible)]="showAddMemo" [responsive]="true" [modal]="true"
    [style]="{width: '35vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false">

    <div class="p-fluid p-formgrid p-grid" *ngIf="selectedRowData && selectedRowData['ClientName']">
        <div class="p-field p-col-12 form-group">
            <label class="p-col-12">{{selectedRowData['ClientName']}}</label>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid p-mb-4"
        *ngIf="selectedRowData && companyGlobalSetting.isWorkLocationRequired">
        <div class="p-field p-col-12 form-group">
            <label for="staffID" class="label-text p-col-3">{{'Work-Location' | translate}}</label>
            <p-dropdown placeholder="Search Location" #workLocation="ngModel" (focus)="$event.target.select()"
                name="workLocation" id="workLocation" [options]="workLocationsList"
                [(ngModel)]="selectedRowData['WorkLocation']" filter="true">
                <ng-template let-item pTemplate="selectedItem">
                    <span style="vertical-align:middle;">{{item.label}}</span>
                </ng-template>
                <ng-template let-itemdata pTemplate="item">
                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                        <div style="font-size:14px;margin-top:4px">{{itemdata.label}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid p-mb-4" *ngIf="selectedRowData">
        <div class="p-col-12 form-group">
            <label id="memo" class="label-text p-col-12"
                [ngClass]="{'required-field-lg': companyGlobalSetting.TimeMemoRequired, '': !companyGlobalSetting.TimeMemoRequired}">{{'work.description'
                | translate}}</label>
            <div class="p-col-12">
                <app-mentions [(inputText)]="selectedRowData['Memo']" (inputTextChange)="replaceCaret(selectedRowData, 'Memo');"
                    style="width:100% !important; resize: vertical;"></app-mentions>
            </div>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid p-mb-4" *ngIf="selectedRowData">
        <div class="p-col-12 form-group">
            <label id="privatememo" class="label-text p-col-12">{{'Private-Memo' | translate}}</label>
            <div class="p-col-12">
                <app-mentions [(inputText)]="selectedRowData['PrivateMemo']" (inputTextChange)="replaceCaret(selectedRowData, 'PrivateMemo');"
                    style="width:100% !important; resize: vertical;"></app-mentions>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="btnTypes">
            <button type="button" class="saveBtn" pButton (click)="saveMemo(selectedRowData)"
                label="{{'save_button' | translate}}"></button>
            <button type="button" class="cancelBtn" pButton (click)="showAddMemo=false;"
                label="{{'cancel_button' | translate}}"></button>
        </div>
    </p-footer>
</p-dialog>